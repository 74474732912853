import { NoDataFound } from 'app/components/project/NoDataFound';
import { LineChartIcon } from 'lucide-react';
import { CollapsibleChart } from './CollapsibleChart';
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip as RechartsToolTip,
  Legend,
} from 'recharts';
import { TrendDataPoint } from 'app/types/visualizations';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
  createTemperatureYAxisProps,
} from './shared/chartProps';
import { useState, useMemo } from 'react';
import { Checkbox } from '@shadcn/ui/checkbox';
import { Label } from '@shadcn/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/select';

interface UsageTrendChartProps {
  utilityBillsData: TrendDataPoint[];
  meterReadingsData: TrendDataPoint[];
  units: string;
}

interface MeterGroupsResult {
  data: Array<{
    billingPeriod: string;
    [key: string]: any;
  }>;
  meterNames: string[];
}

export const UsageTrendChart = ({ utilityBillsData, meterReadingsData, units }: UsageTrendChartProps) => {
  const dataSource = useMemo(() => (utilityBillsData.length > 0 ? 'utility-bills' : 'energy-star'), [utilityBillsData]);

  const [selectedMeters, setSelectedMeters] = useState<Set<string>>(new Set());
  const [selectedYear, setSelectedYear] = useState<string>('all');

  // Extract available years from the data
  const availableYears = useMemo(() => {
    const years = new Set<string>();
    const data = dataSource === 'utility-bills' ? utilityBillsData : meterReadingsData;

    data.forEach((item) => {
      const year = new Date(item.billingPeriod.split('_')[0].replace(/-/g, '/')).getFullYear().toString();
      years.add(year);
    });

    return Array.from(years).sort().reverse();
  }, [dataSource, utilityBillsData, meterReadingsData]);

  // Filter data by selected year
  const filterDataByYear = (data: any[]) => {
    if (selectedYear === 'all') return data;

    return data.filter((item) => {
      const itemYear = new Date(item.billingPeriod.split('_')[0].replace(/-/g, '/')).getFullYear().toString();
      return itemYear === selectedYear;
    });
  };

  // Group meter readings by meter name
  const meterGroups = useMemo(() => {
    if (!meterReadingsData?.length) return { data: [], meterNames: [] } as MeterGroupsResult;

    // First, create a map of billing periods to combined data points
    const combinedData = new Map();

    meterReadingsData.forEach((reading) => {
      const meterName = reading.meterName || 'Unknown Meter';
      if (!combinedData.has(reading.billingPeriod)) {
        combinedData.set(reading.billingPeriod, {
          billingPeriod: reading.billingPeriod,
          temperature: reading.temperature,
          totalUsage: reading.totalUsage,
          monthYear: reading.monthYear,
        });
      }
      const dataPoint = combinedData.get(reading.billingPeriod);
      dataPoint[`${meterName}_usage`] = reading.totalUsage;
    });

    // Convert to array and sort by billing period
    const sortedData = Array.from(combinedData.values()).sort((a, b) => {
      const aStartDate = new Date(a.billingPeriod.split('_')[0].replace(/-/g, '/'));
      const bStartDate = new Date(b.billingPeriod.split('_')[0].replace(/-/g, '/'));
      return aStartDate.getTime() - bStartDate.getTime();
    });

    // Get unique meter names
    const meterNames = new Set(meterReadingsData.map((r) => r.meterName || 'Unknown Meter'));

    // Initialize selected meters if empty
    if (selectedMeters.size === 0) {
      setSelectedMeters(new Set(meterNames));
    }

    return {
      data: sortedData,
      meterNames: Array.from(meterNames),
    };
  }, [meterReadingsData]);

  const renderYearFilter = () => {
    if (!availableYears.length) return null;

    return (
      <div className="mb-4 mt-2 flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Filter by Year:</Label>
        <Select value={selectedYear} onValueChange={setSelectedYear}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Years</SelectItem>
            {availableYears.map((year) => (
              <SelectItem key={year} value={year}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderMeterSelector = () => {
    if (dataSource !== 'energy-star' || !meterGroups.meterNames.length) return null;

    return (
      <div className="mb-4 space-y-2">
        <Label className="text-sm font-medium text-muted-foreground">Select Meters to Display:</Label>
        <div className="flex flex-wrap gap-4">
          {meterGroups.meterNames.map((meterName) => (
            <div key={meterName} className="flex items-center space-x-2">
              <Checkbox
                id={meterName}
                checked={selectedMeters.has(meterName)}
                onCheckedChange={(checked) => {
                  setSelectedMeters((prev) => {
                    const newSet = new Set(prev);
                    if (checked) {
                      newSet.add(meterName);
                    } else {
                      newSet.delete(meterName);
                    }
                    return newSet;
                  });
                }}
              />
              <Label
                htmlFor={meterName}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {meterName}
              </Label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderChart = () => {
    // Select and filter the appropriate data based on the data source and year
    let chartData = dataSource === 'utility-bills' ? utilityBillsData : meterGroups.data;
    chartData = filterDataByYear(chartData);

    if (dataSource === 'utility-bills') {
      chartData = [...chartData].sort((a, b) => {
        const aStartDate = new Date(a.billingPeriod.split('_')[0].replace(/-/g, '/'));
        const bStartDate = new Date(b.billingPeriod.split('_')[0].replace(/-/g, '/'));
        return aStartDate.getTime() - bStartDate.getTime();
      });
    }

    if (!chartData || chartData.length === 0) {
      return (
        <NoDataFound
          message="No usage trend data available"
          submessage={
            dataSource === 'energy-star'
              ? 'Energy Star meter readings are not yet available'
              : 'There is no usage trend data to display for this time period'
          }
          icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
        />
      );
    }

    const hasTemperature = chartData.some((point) => point.temperature !== undefined);

    return (
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData} margin={{ top: 20, right: 80, left: 80, bottom: 100 }}>
          <CartesianGrid {...sharedCartesianGridProps} />
          <XAxis
            {...sharedXAxisProps}
            label={{
              position: 'bottom',
              offset: 10,
            }}
          />
          <YAxis {...createYAxisProps(`Energy Consumption (${units})`)} tickCount={20} interval={0} />
          {hasTemperature && <YAxis {...createTemperatureYAxisProps()} tickCount={15} interval={0} yAxisId="right" />}
          <RechartsToolTip {...sharedTooltipProps} />
          <Legend {...sharedLegendProps} />
          {dataSource === 'utility-bills' ? (
            <Line
              yAxisId="left"
              unit={` ${units}`}
              name={`Energy Consumption (${units})`}
              type="linear"
              dataKey="totalUsage"
              stroke="rgb(var(--chart-energy-rgb))"
              activeDot={{ r: 8 }}
            />
          ) : (
            // Render a line for each selected meter using the combined data
            meterGroups.meterNames
              .filter((meterName) => selectedMeters.has(meterName))
              .map((meterName, index) => (
                <Line
                  key={meterName}
                  yAxisId="left"
                  unit={` ${units}`}
                  name={`${meterName} (${units})`}
                  type="linear"
                  dataKey={`${meterName}_usage`}
                  stroke={index === 0 ? 'rgb(var(--chart-energy-rgb))' : 'rgb(21, 128, 61)'}
                  strokeWidth={index === 0 ? 2 : 3}
                  dot={index === 0 ? { r: 4 } : { r: 4, strokeWidth: 2 }}
                  activeDot={{ r: 8, strokeWidth: 2 }}
                />
              ))
          )}
          {hasTemperature && (
            <Line
              unit=" °F"
              name="Temperature"
              yAxisId="right"
              type="linear"
              dataKey="temperature"
              stroke="rgb(var(--chart-temperature-rgb))"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <CollapsibleChart title={'Usage Trend'}>
      <div className="mb-6 space-y-2">
        {renderYearFilter()}
        {renderMeterSelector()}
      </div>
      {renderChart()}
    </CollapsibleChart>
  );
};
