import { useState, useEffect } from 'react';
import { useTasks } from 'app/hooks/useTasks';
import { useProjects } from 'app/hooks/useProjects';
import energyStarServices from 'app/services/energy-star-services';
import syncServices from 'app/services/sync-services';
import { MainContent } from 'app/components/home/MainContent';
import type { Task } from 'app/types/task';
import type { ProjectInfoData } from 'app/services/project-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useToast } from '../../../@shadcn/hooks/use-toast';
import { extractError } from 'app/utils/appHelpers';

export const Home = () => {
  const { authUser } = useJumboAuth();
  const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;

  const [energyStarProperties, setEnergyStarProperties] = useState<any[]>([]);
  const { projects, setRefreshProjects } = useProjects();

  useEffect(() => {
    const fetchEnergyStarProperties = async () => {
      if (energyStarAccountId) {
        try {
          const [properties] = await Promise.all([energyStarServices.listProperties(energyStarAccountId)]);
          setEnergyStarProperties(properties['properties']);
        } catch (error) {
          console.error('Error fetching Energy Star properties:', error);
        }
      }
    };

    fetchEnergyStarProperties();
  }, [energyStarAccountId]);

  const tasks = useTasks(authUser, projects, energyStarProperties);

  const [showProjectsTable, setShowProjectsTable] = useState(false);
  const [showEnergyStarProperties, setShowEnergyStarProperties] = useState(false);
  const [propertyScores, setPropertyScores] = useState<Record<string, number>>({});
  const [reviewingProject, setReviewingProject] = useState<{ projectId: string; isVisible: boolean } | null>(null);
  const [syncedProperties, setSyncedProperties] = useState<Set<string>>(new Set());
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { toast } = useToast();

  useEffect(() => {
    if (selectedTask?.id === 'sync-energy-star') {
      setShowEnergyStarProperties(true);
      setShowProjectsTable(false);
    }
  }, [selectedTask]);

  const handleSyncWithEnergyStarClick = async () => {
    try {
      const [properties] = await Promise.all([energyStarServices.listProperties(energyStarAccountId)]);
      setEnergyStarProperties(properties['properties']);
      setShowEnergyStarProperties(true);
      setShowProjectsTable(false);
    } catch (error) {
      console.error('Error syncing with Energy Star Account:', error);
    }
  };

  const handleSyncEnergyStarProperty = async (customerId: string, propertyId: string, parentId?: string) => {
    try {
      const energyStarCustomerId = customerId || energyStarAccountId;
      await syncServices.syncWithEnergyStar(energyStarCustomerId, propertyId, parentId);
      setSyncedProperties((prev) => new Set(prev).add(propertyId));
    } catch (error) {
      console.error('Error syncing project:', error);
      handleSnackbar(extractError(error) ?? 'Failed to sync Energy Star property', 'destructive');
    }
  };

  const handleSnackbar = (message: string, variant: 'default' | 'destructive' | 'positive') => {
    toast({
      description: message,
      variant,
    });
  };

  return (
    <div className="flex h-screen">
      <MainContent
        tasks={tasks}
        projects={projects}
        showProjectsTable={showProjectsTable}
        showEnergyStarProperties={showEnergyStarProperties}
        propertyScores={propertyScores}
        setRefreshProjects={() => setRefreshProjects((prev) => !prev)}
        reviewingProject={reviewingProject}
        energyStarProperties={energyStarProperties}
        syncedProperties={syncedProperties}
        onTaskClick={setSelectedTask}
        onReviewClick={(project: ProjectInfoData) =>
          setReviewingProject({
            projectId: project.id,
            isVisible: true,
          })
        }
        onSubmitScore={(propertyName: string) => setPropertyScores((prev) => ({ ...prev, [propertyName]: 75 }))}
        onPropertySync={handleSyncEnergyStarProperty}
        onReviewClose={() => setReviewingProject(null)}
        onSnackbar={handleSnackbar}
        onSyncWithEnergyStar={handleSyncWithEnergyStarClick}
      />
    </div>
  );
};
