import { formatNumber } from 'app/utils/formatters';
import {
  Cell,
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

interface ChartProps {
  type: 'line' | 'bar' | 'pie';
  data: {
    datasets: Array<{
      data: any[];
      title: string;
      xAxisLabel: string;
      yAxisLabel: string;
      timeInterval: string;
    }>;
    labels: string[];
  };
  options?: any;
}

export const ReportChart = ({ type, data, options }: ChartProps) => {
  if (!data?.labels || !data?.datasets?.[0]?.data) {
    return null;
  }

  const transformedData = data.labels.map((label, index) => ({
    name: label,
    value: data.datasets[0].data[index],
  }));

  const chartTitle = options.title;
  const yAxisLabel = options.yAxisLabel;
  const xAxisLabel = options.xAxisLabel;

  switch (type) {
    case 'line':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={transformedData} margin={{ top: 20, right: 30, left: 50, bottom: 20 }}>
            <text x={300} y={20} textAnchor="middle" dominantBaseline="hanging" fill="#000">
              {chartTitle}
            </text>
            <XAxis dataKey="name" label={{ value: xAxisLabel, position: 'insideBottom', offset: -20 }} />
            <YAxis
              label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }}
              tickFormatter={(value) => formatNumber(Number(value))}
            />
            <Tooltip formatter={(value) => [`${formatNumber(Number(value))} `, yAxisLabel]} />
            <Line type="monotone" dataKey="value" stroke="hsl(200, 70%, 50%)" />
          </LineChart>
        </ResponsiveContainer>
      );

    case 'bar':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={transformedData} margin={{ top: 20, right: 30, left: 50, bottom: 20 }}>
            <text x={300} y={0} textAnchor="middle" dominantBaseline="hanging" fill="#F9FAFB">
              {chartTitle}
            </text>
            <XAxis dataKey="name" label={{ value: xAxisLabel, position: 'insideBottom', offset: -20 }} />
            <YAxis
              label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', offset: -35 }}
              tickFormatter={(value) => formatNumber(Number(value))}
            />
            <Tooltip
              separator=""
              formatter={(value, name, props) => [
                `${xAxisLabel}: ${props.payload.name}`,
                `${yAxisLabel}: ${formatNumber(Number(value))}`,
              ]}
              itemStyle={{
                display: 'flex',
                flexDirection: 'column',
                padding: '4px 0',
              }}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 12px',
              }}
              wrapperStyle={{
                outline: 'none',
              }}
            />
            <Bar dataKey="value" fill="hsl(200, 70%, 50%)" />
          </BarChart>
        </ResponsiveContainer>
      );

    case 'pie':
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];
      const total = transformedData.reduce((sum, entry) => sum + entry.value, 0);

      return (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart margin={{ top: 80, right: 30, left: 30, bottom: 20 }}>
            <text x={300} y={20} textAnchor="middle" dominantBaseline="hanging" fill="#F9FAFB">
              {chartTitle}
            </text>
            <Pie
              data={transformedData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={({ name, value, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`}
            >
              {transformedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value, name) => [
                `${formatNumber(Number(value))} kBtu (${((Number(value) / total) * 100).toFixed(1)}%)`,
                name,
              ]}
            />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      );

    default:
      return null;
  }
};
