import { formatNumber } from 'app/utils/formatters';
import { PenaltyData } from 'app/types/penalty-data';
import { TrendingDown, TrendingUp } from 'lucide-react';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import type { PropertyMetrics } from 'app/services/energy-star-services';
interface YearlyEnergySummaryProps {
  data?: PenaltyData['estimatedAnnual'];
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
}

const YearlyEnergySummary = ({ data, energyStarScoreByDateRange }: YearlyEnergySummaryProps) => {
  // Sort years in descending order (most recent first)
  const sortedYears = (data?.yearsData ? [...data.yearsData] : [])
    .filter((year) => !(year.eui.siteEUI === 0 && year.eui.sourceEUI === 0))
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

  // Determine which services have data
  const activeServices = {
    electric: sortedYears.some((year) => year.energySums?.electric),
    gas: sortedYears.some((year) => year.energySums?.gas),
    steam: sortedYears.some((year) => year.energySums?.steam),
    oil: sortedYears.some((year) => year.energySums?.oil),
  };

  return (
    <div>
      {/* Yearly Usage Table */}
      <CollapsibleChart title="Yearly Usage">
        <div className="overflow-x-auto rounded-lg border border-border/10">
          <table className="w-full min-w-[800px] border-separate border-spacing-0">
            <thead className="sticky top-0 bg-muted/80 backdrop-blur supports-[backdrop-filter]:bg-muted/60">
              <tr>
                <th className="border-b border-border/20 p-4 text-left text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Time Period
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Site EUI
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Source EUI
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Total Emissions (tCO₂)
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Energy Star Score
                </th>
              </tr>
            </thead>
            <tbody className="bg-background/40">
              {sortedYears.map((year, index) => {
                const prevYear = sortedYears[index + 1];
                const emissionsChange = prevYear ? year.actualEmissions - prevYear.actualEmissions : 0;
                const dateRange = `${new Date(year.startDate).toLocaleDateString()} - ${new Date(year.endDate).toLocaleDateString()}`;
                const energyStarScore = energyStarScoreByDateRange?.[dateRange];
                const energyStarScoreValue = energyStarScore?.score;
                return (
                  <tr key={year.year} className="transition-colors hover:bg-muted/30">
                    <td className="border-b border-border/10 p-4 text-sm">
                      <div className="text-foreground/90">
                        {new Date(year.startDate).toLocaleDateString()} - {new Date(year.endDate).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="border-b border-border/10 p-4 text-right">
                      <div className="text-sm text-foreground/90">{formatNumber(year.eui.siteEUI)}</div>
                    </td>
                    <td className="border-b border-border/10 p-4 text-right">
                      <div className="text-sm text-foreground/90">{formatNumber(year.eui.sourceEUI)}</div>
                    </td>
                    <td className="border-b border-border/10 p-4 text-right">
                      <div className="flex items-center justify-end gap-1">
                        <span className="text-sm text-foreground/90">{formatNumber(year.actualEmissions)}</span>
                        {emissionsChange !== 0 && (
                          <span className={emissionsChange < 0 ? 'text-green-500' : 'text-red-500'}>
                            {emissionsChange < 0 ? (
                              <TrendingDown className="h-4 w-4" />
                            ) : (
                              <TrendingUp className="h-4 w-4" />
                            )}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="border-b border-border/10 p-4 text-right">
                      <div className="text-sm text-foreground/90">
                        {energyStarScoreValue ? Math.round(energyStarScoreValue) : 'N/A'}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </CollapsibleChart>

      {/* Yearly Usage by Service Table */}
      <CollapsibleChart title="Yearly Usage by Service">
        <div className="overflow-x-auto rounded-lg border border-border/10">
          <table className="w-full min-w-[800px] border-separate border-spacing-0">
            <thead className="sticky top-0 bg-muted/80 backdrop-blur supports-[backdrop-filter]:bg-muted/60">
              <tr>
                <th className="border-b border-border/20 p-4 text-left text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Time Period
                </th>
                {activeServices.electric && (
                  <th
                    colSpan={3}
                    className="border-b border-border/20 p-4 text-center text-xs font-medium uppercase tracking-wider text-foreground/80"
                  >
                    Electricity
                  </th>
                )}
                {activeServices.gas && (
                  <th
                    colSpan={3}
                    className="border-b border-border/20 p-4 text-center text-xs font-medium uppercase tracking-wider text-foreground/80"
                  >
                    Natural Gas
                  </th>
                )}
                {activeServices.steam && (
                  <th
                    colSpan={3}
                    className="border-b border-border/20 p-4 text-center text-xs font-medium uppercase tracking-wider text-foreground/80"
                  >
                    Steam
                  </th>
                )}
                {activeServices.oil && (
                  <th
                    colSpan={3}
                    className="border-b border-border/20 p-4 text-center text-xs font-medium uppercase tracking-wider text-foreground/80"
                  >
                    Oil
                  </th>
                )}
              </tr>
              <tr>
                <th className="border-b border-border/20 p-4 text-left text-xs font-medium uppercase tracking-wider text-foreground/80"></th>
                {activeServices.electric && (
                  <>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      kWh
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      kBtu
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      Emissions (tCO₂)
                    </th>
                  </>
                )}
                {activeServices.gas && (
                  <>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      Therms
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      kBtu
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      Emissions (tCO₂)
                    </th>
                  </>
                )}
                {activeServices.steam && (
                  <>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      mLbs
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      kBtu
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      Emissions (tCO₂)
                    </th>
                  </>
                )}
                {activeServices.oil && (
                  <>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      gallons
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      kBtu
                    </th>
                    <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                      Emissions (tCO₂)
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody className="bg-background/40">
              {sortedYears.map((year) => (
                <tr key={year.year} className="transition-colors hover:bg-muted/30">
                  <td className="border-b border-border/10 p-4 text-sm">
                    <div className="text-foreground/90">
                      {new Date(year.startDate).toLocaleDateString()} - {new Date(year.endDate).toLocaleDateString()}
                    </div>
                  </td>
                  {/* Electricity */}
                  {activeServices.electric && (
                    <>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySums?.electric ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">
                          {formatNumber(year.energySumsKBTU?.electric ?? 0)}
                        </div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">
                          {formatNumber(year.emissionsByType?.electric ?? 0)}
                        </div>
                      </td>
                    </>
                  )}
                  {/* Natural Gas */}
                  {activeServices.gas && (
                    <>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySums?.gas ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySumsKBTU?.gas ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.emissionsByType?.gas ?? 0)}</div>
                      </td>
                    </>
                  )}
                  {/* Steam */}
                  {activeServices.steam && (
                    <>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySums?.steam ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">
                          {formatNumber(year.energySumsKBTU?.steam ?? 0)}
                        </div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">
                          {formatNumber(year.emissionsByType?.steam ?? 0)}
                        </div>
                      </td>
                    </>
                  )}
                  {/* Oil */}
                  {activeServices.oil && (
                    <>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySums?.oil ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.energySumsKBTU?.oil ?? 0)}</div>
                      </td>
                      <td className="border-b border-border/10 p-4 text-right">
                        <div className="text-sm text-foreground/90">{formatNumber(year.emissionsByType?.oil ?? 0)}</div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CollapsibleChart>
    </div>
  );
};

export default YearlyEnergySummary;
