import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import { DownloadIcon, EllipsisVerticalIcon, LoaderCircleIcon, TrashIcon } from 'lucide-react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardFooter,
  Badge,
  Button,
  ScrollArea,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  toastVariants,
} from '@shadcn/ui';
import { format } from 'date-fns';

import reportServices from 'app/services/report-services';
import projectServices from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';
import { NewReportButton } from '../components/NewReportButton';
import type { YearData } from 'app/types/penalty-data';

type ReportOutputType = 'xlsx' | 'pdf';

type Report = {
  id: string;
  attributes: {
    name: string;
    status: string;
    createdAt: string;
    outputType: ReportOutputType;
  };
};

const getBadgeVariant = (status: string) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return 'positive';
    case 'in progress':
      return 'warning';
    case 'failed':
      return 'destructive';
    default:
      return 'default';
  }
};

interface ReportsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const Exports = forwardRef<HTMLDivElement, ReportsProps>(({ projectId, onSnackbar }, ref) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openNewReportDialog, setOpenNewReportDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState<ReportOutputType | null>(null);
  const [yearsData, setYearsData] = useState<YearData[]>([]);

  const fetchReports = useCallback(async () => {
    try {
      return await reportServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('An unexpected error occurred while fetching the reports', 'destructive');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const fetchedReports = await fetchReports();

        if (isMounted) {
          setReports(fetchedReports);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchReports, projectId, refreshTrigger]);

  const handleOpenMenu = (report: Report) => {
    setSelectedReport(report);
  };

  const handleCloseMenu = () => {
    setSelectedReport(null);
  };

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;

      link.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error: unknown) {
      if (error instanceof Error) {
        onSnackbar(error.message || 'Failed to download report', 'destructive');
      } else {
        onSnackbar('Failed to download report', 'destructive');
      }
    }
  };

  const handleDownload = async (report: Report) => {
    const { id: reportId } = report;
    try {
      const downloadUrl = await reportServices.getDownloadLink(reportId);
      downloadFile(downloadUrl, reportId);
      handleCloseMenu();
    } catch (e) {
      onSnackbar('Failed to downlaod report.', 'destructive');
    }
  };

  const handleDeleteReport = async () => {
    if (!selectedReport) {
      return;
    }
    setIsDeleting(true);

    try {
      const { status } = await reportServices.delete(selectedReport.id);
      if (status === 204) {
        onSnackbar('Report deleted successfully', 'positive');
        setRefreshTrigger((prev) => prev + 1);
        handleCloseMenu();
      } else {
        throw new Error('Failed to delete the report');
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to delete the report', 'destructive');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOpenNewReportDialog = () => {
    setOpenNewReportDialog(true);
  };

  const handleCloseNewReportDialog = () => {
    setOpenNewReportDialog(false);
  };

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Exports</h2>
        <NewReportButton
          projectId={projectId}
          outputType="xlsx"
          isDeleting={isDeleting}
          setRefreshTrigger={setRefreshTrigger}
          selectedReportType={selectedReportType}
          setSelectedReportType={setSelectedReportType}
          openNewReportDialog={openNewReportDialog}
          setOpenNewReportDialog={setOpenNewReportDialog}
          yearsData={yearsData}
        />
      </div>

      {reports.length ? (
        <ScrollArea
          orientation="vertical"
          className={cn('grow p-4', {
            '': !reports.length,
          })}
        >
          <div className="flex flex-col gap-8">
            {/* Emissions Reports Section */}
            <div>
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-lg font-semibold">Emissions Reports</h3>
              </div>
              <div className="grid grid-cols-1 gap-6 pt-1 md:grid-cols-3 lg:grid-cols-4">
                {reports
                  .filter((report) => report.attributes.outputType === 'pdf')
                  .map((report) => (
                    <Card className="min-h-48 rounded-xl border border-border shadow-xl" key={report.id}>
                      <CardHeader className="flex w-full flex-row items-center justify-between">
                        <CardTitle>{report.attributes.name}</CardTitle>

                        <DropdownMenu
                          open={selectedReport?.id === report.id}
                          onOpenChange={(v) => (v ? handleOpenMenu(report) : handleCloseMenu())}
                        >
                          <DropdownMenuTrigger asChild>
                            <Button size="icon" variant="ghost" disabled={isDeleting}>
                              <EllipsisVerticalIcon />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              className="flex flex-row gap-2"
                              variant="destructive"
                              onClick={handleDeleteReport}
                            >
                              <TrashIcon className="h-6 w-6" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </CardHeader>
                      <CardContent>
                        <p className="mb-4 text-muted-foreground">
                          Created: {format(new Date(report.attributes.createdAt), 'MMM d, yyyy HH:mm')}
                        </p>
                        <div className="mt-2 flex items-start justify-between">
                          <Badge variant={getBadgeVariant(report.attributes.status)}>
                            {report.attributes.status.toUpperCase()}
                          </Badge>
                        </div>
                      </CardContent>
                      <CardFooter>
                        {report.attributes.status === 'completed' ? (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleDownload(report)}
                                disabled={isDeleting}
                              >
                                <DownloadIcon />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>Download Report</TooltipContent>
                          </Tooltip>
                        ) : null}
                      </CardFooter>
                    </Card>
                  ))}
              </div>
            </div>

            {/* Excel Reports Section */}
            <div>
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-lg font-semibold">Excel Reports</h3>
              </div>
              <div className="grid grid-cols-1 gap-6 pt-1 md:grid-cols-3 lg:grid-cols-4">
                {reports
                  .filter((report) => report.attributes.outputType === 'xlsx')
                  .map((report) => (
                    <Card className="min-h-48 rounded-xl border border-border shadow-xl" key={report.id}>
                      <CardHeader className="flex w-full flex-row items-center justify-between">
                        <CardTitle>{report.attributes.name}</CardTitle>

                        <DropdownMenu
                          open={selectedReport?.id === report.id}
                          onOpenChange={(v) => (v ? handleOpenMenu(report) : handleCloseMenu())}
                        >
                          <DropdownMenuTrigger asChild>
                            <Button size="icon" variant="ghost" disabled={isDeleting}>
                              <EllipsisVerticalIcon />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              className="flex flex-row gap-2"
                              variant="destructive"
                              onClick={handleDeleteReport}
                            >
                              <TrashIcon className="h-6 w-6" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </CardHeader>
                      <CardContent>
                        <p className="mb-4 text-muted-foreground">
                          Created: {format(new Date(report.attributes.createdAt), 'MMM d, yyyy HH:mm')}
                        </p>
                        <div className="mt-2 flex items-start justify-between">
                          <Badge variant={getBadgeVariant(report.attributes.status)}>
                            {report.attributes.status.toUpperCase()}
                          </Badge>
                        </div>
                      </CardContent>
                      <CardFooter>
                        {report.attributes.status === 'completed' ? (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleDownload(report)}
                                disabled={isDeleting}
                              >
                                <DownloadIcon />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>Download Report</TooltipContent>
                          </Tooltip>
                        ) : null}
                      </CardFooter>
                    </Card>
                  ))}
              </div>
            </div>
          </div>
        </ScrollArea>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <p className="text-muted-foreground">No exports yet. Build a new report or export data to get started.</p>
        </div>
      )}
    </div>
  );
});
