import { ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';

export const formatNumber = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    useGrouping: true,
  }).format(value);
};

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
};

export const formatChartTick = (num, decimalPlaces = 0) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '0';
  }

  const parts = Number(num).toFixed(decimalPlaces).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatSquareFeet = (squareFeet: number) => {
  return new Intl.NumberFormat('en-US').format(squareFeet);
};

export const formatTemperature = (value: number): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return '0°';
  }
  return `${Math.round(value)}°`;
};

export const getUsageUnit = (meterType: string) => {
  switch (meterType.toLowerCase()) {
    case ELECTRIC:
      return 'kWh';
    case 'Natural Gas':
      return 'therms';
    case GAS:
      return 'therms';
    case STEAM:
      return 'Mlbs';
    case 'District Steam':
      return 'Mlbs';
    case WATER:
      return 'CCF';
    case OIL:
      return 'gallons';
    default:
      return '';
  }
};
