import { VariantProps } from 'class-variance-authority';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { ScrollArea, toastVariants, Tabs, TabsList, TabsTrigger, TabsContent } from '@shadcn/ui';
import documentServices from 'app/services/document-services';
import { extractError } from 'app/utils/appHelpers';
import {
  CloudFogIcon,
  LoaderCircleIcon,
  BoltIcon,
  FlameIcon,
  DropletIcon,
  FileIcon,
  FuelIcon,
  ListIcon,
  GaugeIcon,
} from 'lucide-react';
import { ELECTRIC, GAS, STEAM, OIL, WATER, UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import energyStarServices from 'app/services/energy-star-services';
import projectServices from 'app/services/project-services';
import { theme } from 'app/utils/theme';
import type { Meter, ConsumptionData } from 'app/types/energy-star';

import { FileWithMetadata } from './document-steps/DocumentUpload';
import { DocumentsTable } from '../components/DocumentsTable';
import { DataAlert } from 'app/components/DataAlert';
import { AddDocumentButton } from '../components/AddDocumentButton';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { EnergyStarMeters } from './EnergyStarMeters';
import { ServiceTabs } from 'app/components/service/ServiceTabs';

export interface Document {
  _id: string;
  attributes: {
    fileName: string;
    documentType: string;
    status: string;
    createdAt: string;
    fileSize: number;
    detectedData?: Record<string, any>;
  };
}

interface DocumentPageProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

const ServiceTypeIcon = ({ type }: { type: string }) => {
  switch (type) {
    case ELECTRIC:
      return <BoltIcon className="h-4 w-4" />;
    case GAS:
      return <FlameIcon className="h-4 w-4" />;
    case WATER:
      return <DropletIcon className="h-4 w-4" />;
    case OIL:
      return <FuelIcon className="h-4 w-4" />;
    default:
      return <ListIcon className="h-4 w-4" />;
  }
};

export const DataSources = forwardRef<HTMLDivElement, DocumentPageProps>(({ projectId, onSnackbar }, ref) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [meters, setMeters] = useState<Meter[]>([]);
  const [consumptionData, setConsumptionData] = useState<Record<string, ConsumptionData>>({});
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchDocuments = useCallback(async () => {
    try {
      return await documentServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('Failed to fetch documents', 'destructive');
    }
  }, [projectId, onSnackbar]);

  const fetchMeters = useCallback(async () => {
    try {
      const projectData = await projectServices.getById(projectId);
      if (!projectData.attributes.energyStarId) {
        return [];
      }

      const metersData = await energyStarServices.listMeters(projectData.attributes.energyStarId);
      return metersData['meters'];
    } catch (error) {
      console.error('Error fetching meters:', error);
      return [];
    }
  }, [projectId]);

  const fetchMeterConsumption = useCallback(async (meterId: string) => {
    try {
      const consumptionData = await energyStarServices.getMeterConsumption(meterId);
      return consumptionData;
    } catch (error) {
      console.error('Error fetching meter consumption:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchDocuments()
      .then((docs) => {
        if (isMounted && docs) {
          setDocuments(docs);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [fetchDocuments, refreshTrigger]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const loadData = async () => {
      const metersData = await fetchMeters();
      if (!isMounted) return;

      setMeters(metersData);

      const consumptionPromises = metersData.map((meter) =>
        fetchMeterConsumption(meter.id).then((consumption) => ({
          meterId: meter.id,
          consumption,
        }))
      );

      const consumptionResults = await Promise.all(consumptionPromises);
      if (!isMounted) return;

      const consumptionMap = consumptionResults.reduce(
        (acc, { meterId, consumption }) => {
          if (consumption) {
            acc[meterId] = consumption;
          }
          return acc;
        },
        {} as Record<string, ConsumptionData>
      );

      setConsumptionData(consumptionMap);
      setIsLoading(false);
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [fetchMeters, fetchMeterConsumption, refreshTrigger]);

  const handleDocumentDelete = async (fileId: string) => {
    setIsLoading(true);

    const msg = { success: 'Document(s) deleted successfully.', fail: 'Failed to delete document(s).' };
    try {
      const result = await documentServices.delete(fileId);
      if (result && result.status === 204) {
        onSnackbar(msg.success, 'destructive');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true };
      } else {
        throw new Error(msg.fail);
      }
    } catch (error) {
      onSnackbar(extractError(error) || msg.fail, 'destructive');
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async (files: FileWithMetadata[], billBreaks: Record<string, number[]>) => {
    setIsLoading(true);

    try {
      const results = await documentServices.create({
        files,
        projectId,
        billBreaks,
      });

      onSnackbar('Documents created successfully.', 'positive');
      setRefreshTrigger((prev) => prev + 1);
      setIsUploadDialogOpen(false);
      return results;
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to create documents.', 'destructive');
    } finally {
      setIsLoading(false);
    }
  };

  interface DuplicateData {
    type: 'billing_period' | 'amount_due';
    documents: {
      id: string;
      fileName: string;
      value: string;
    }[];
  }

  const findDuplicates = useCallback((docs: Document[]): DuplicateData[] => {
    const duplicates: DuplicateData[] = [];
    const billingPeriodMap = new Map<string, typeof docs>();
    const amountDueMap = new Map<string, typeof docs>();

    docs.forEach((doc) => {
      if (!doc.attributes.detectedData?.billing_summary) return;

      // Check billing period
      const startDate = doc.attributes.detectedData.billing_summary.billing_start_date;
      const endDate = doc.attributes.detectedData.billing_summary.billing_end_date;

      if (startDate && endDate) {
        const periodKey = `${startDate}_${endDate}`;
        const existing = billingPeriodMap.get(periodKey) || [];
        billingPeriodMap.set(periodKey, [...existing, doc]);
      }

      // Check amount due
      const amountDue = doc.attributes.detectedData.billing_summary.total_this_billing_period;
      if (amountDue) {
        const amountKey = amountDue.toString();
        const existing = amountDueMap.get(amountKey) || [];
        amountDueMap.set(amountKey, [...existing, doc]);
      }
    });

    // Add billing period duplicates
    billingPeriodMap.forEach((docs, period) => {
      if (docs.length > 1) {
        const [start, end] = period.split('_');
        duplicates.push({
          type: 'billing_period',
          documents: docs.map((doc) => ({
            id: doc._id,
            fileName: doc.attributes.fileName,
            value: `${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}`,
          })),
        });
      }
    });

    // Add amount due duplicates
    amountDueMap.forEach((docs, amount) => {
      if (docs.length > 1) {
        duplicates.push({
          type: 'amount_due',
          documents: docs.map((doc) => ({
            id: doc._id,
            fileName: doc.attributes.fileName,
            value: `$${parseFloat(amount).toFixed(2)}`,
          })),
        });
      }
    });

    return duplicates;
  }, []);

  const getServiceDocuments = (docs: Document[], service: string) => {
    if (service === 'All') {
      return docs;
    }
    return docs.filter(
      (doc) => doc.attributes.detectedData && Object.keys(doc.attributes.detectedData).includes(service.toLowerCase())
    );
  };

  const allServices = ['All', ...UTILITY_SERVICES];

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <AddDocumentButton
          onDocumentCreate={handleCreateDocument}
          variant={documents.length ? 'default' : 'secondary'}
        />
      </div>
      <ScrollArea orientation="both" className="p-4">
        <Tabs defaultValue="utility-bills" className="w-full">
          <TabsList className="mb-6 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted/5 p-1.5 shadow-[inset_0_1px_3px_rgba(0,0,0,0.2)]">
            <TabsTrigger
              value="utility-bills"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/5 bg-background/20 text-muted-foreground backdrop-blur-sm transition-all hover:bg-background/30 data-[state=active]:border-primary/20 data-[state=active]:bg-primary/90 data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_8px_rgba(124,58,237,0.35)]"
            >
              <FileIcon className="h-4 w-4" />
              Utility Bills
            </TabsTrigger>
            <TabsTrigger
              value="meters"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/5 bg-background/20 text-muted-foreground backdrop-blur-sm transition-all hover:bg-background/30 data-[state=active]:border-primary/20 data-[state=active]:bg-primary/90 data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_8px_rgba(124,58,237,0.35)]"
            >
              <GaugeIcon className="h-4 w-4" />
              Meters
            </TabsTrigger>
          </TabsList>

          <TabsContent value="utility-bills">
            {documents.length === 0 ? (
              <NoDataFound
                message="No utility bills found"
                submessage="Upload utility bills to get started"
                icon={<FileIcon className="h-8 w-8 text-muted-foreground" />}
              />
            ) : (
              <Tabs defaultValue="all" className="w-full">
                <ServiceTabs allServices={allServices} />

                {allServices.map((serviceType) => (
                  <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
                    <DataAlert variant="duplicate" data={findDuplicates(getServiceDocuments(documents, serviceType))} />
                    <DocumentsTable
                      documents={getServiceDocuments(documents, serviceType)}
                      onDelete={handleDocumentDelete}
                      selectedService={serviceType}
                    />
                  </TabsContent>
                ))}
              </Tabs>
            )}
          </TabsContent>

          <TabsContent value="meters">
            <Tabs defaultValue="all" className="w-full">
              <ServiceTabs allServices={allServices} />

              {allServices.map((serviceType) => (
                <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
                  <EnergyStarMeters
                    meters={
                      serviceType === 'All'
                        ? meters
                        : meters.filter((meter) => {
                            switch (serviceType) {
                              case ELECTRIC:
                                return meter.type === 'Electric';
                              case GAS:
                                return meter.type === 'Natural Gas';
                              case STEAM:
                                return meter.type === 'District Steam';
                              case WATER:
                                return meter.type === 'Water';
                              case OIL:
                                return meter.type === 'Fuel Oil';
                              default:
                                return false;
                            }
                          })
                    }
                    consumptionData={consumptionData}
                    theme={theme}
                    displayEmptyMeters={true}
                    selectedService={serviceType}
                  />
                </TabsContent>
              ))}
            </Tabs>
          </TabsContent>
        </Tabs>
      </ScrollArea>
    </div>
  );
});
