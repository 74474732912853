import { useState } from 'react';
import { Button, Dialog, DialogTrigger } from '@shadcn/ui';
import { PlusIcon } from 'lucide-react';
import { DocumentStepper } from './DocumentStepper';
import { FileWithMetadata } from '../data-sources/document-steps/DocumentUpload';

interface AddDocumentButtonProps {
  onDocumentCreate: (files: FileWithMetadata[], billBreaks: Record<string, number[]>) => Promise<any>;
  variant?: 'default' | 'secondary';
}

export const AddDocumentButton = ({ onDocumentCreate, variant = 'default' }: AddDocumentButtonProps) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  return (
    <Dialog open={isUploadDialogOpen} onOpenChange={(v) => setIsUploadDialogOpen(v)}>
      <DialogTrigger asChild>
        <Button variant={variant} onClick={() => setIsUploadDialogOpen(true)}>
          <PlusIcon /> Upload Document
        </Button>
      </DialogTrigger>
      <DocumentStepper
        onDocumentCreate={async (files, billBreaks) => {
          const result = await onDocumentCreate(files, billBreaks);
          setIsUploadDialogOpen(false);
          return result;
        }}
      />
    </Dialog>
  );
};
