import { VariantProps } from 'class-variance-authority';
import { FormEvent, useState, useEffect, useMemo, forwardRef } from 'react';
import { type CheckedState } from '@radix-ui/react-checkbox';
import { Collapsible } from '@shadcn/custom/Collapsible';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { cn } from '@shadcn/utils';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Button,
  Card,
  CardContent,
  Checkbox,
  Label,
  Progress,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  ScrollArea,
  toastVariants,
} from '@shadcn/ui';
import {
  BriefcaseBusinessIcon,
  Building2Icon,
  CalendarIcon,
  HomeIcon,
  InfoIcon,
  MapPinIcon,
  PencilIcon,
  RulerIcon,
  XIcon,
} from 'lucide-react';
import projectServices from 'app/services/project-services';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import type { ProjectInfoData } from 'app/services/project-services';
import { formatSquareFeet } from 'app/utils/formatters';
type BuildingInfoData = {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  squareFeet: string;
  yearBuilt: string;
  services: string[];
  propertyType: string;
  childProperties?: Array<{
    name: string;
    energyStarId: string;
  }>;
};

const blankBuildingInfo: BuildingInfoData = {
  name: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  squareFeet: '',
  yearBuilt: '',
  services: [],
  propertyType: '',
};

interface BuildingInfoProps {
  project: ProjectInfoData;
  onUpdateProject: (data: { id: string; data: any }) => Promise<any>;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

const ChildPropertiesCard = ({
  childProperties,
}: {
  childProperties?: Array<{ name: string; energyStarId: string }>;
}) => {
  if (!childProperties?.length) return null;

  return (
    <Card className="col-span-full animate-zoom-in rounded-xl border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
      <CardContent className="pt-6">
        <div className="mb-4 flex flex-row items-center justify-start gap-2">
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon className="h-4 w-4 text-muted-foreground/80" />
            </TooltipTrigger>
            <TooltipContent>Child properties associated with this building</TooltipContent>
          </Tooltip>
          <p className="text-base font-bold text-muted-foreground/80">Child Properties</p>
        </div>
        <div className="grid gap-4">
          {childProperties.map((child) => (
            <div key={child.energyStarId} className="flex items-center gap-2">
              <Building2Icon className="h-4 w-4 text-muted-foreground/80" />
              <span className="text-lg text-foreground">{child.name}</span>
              <span className="text-sm text-muted-foreground/80">({child.energyStarId})</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

// Update the type used for form fields to exclude both services and childProperties
type BuildingInfoFormFields = Omit<BuildingInfoData, 'services' | 'childProperties'>;

// Add this helper function at the top level
const formatValue = (key: string, value: unknown): string => {
  if (key === 'services') {
    return Array.isArray(value) ? value.join(', ') : 'None selected';
  }
  if (Array.isArray(value)) {
    return 'None selected';
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString() || 'Not provided';
  }
  return 'Not provided';
};

export const BuildingInfo = forwardRef<HTMLDivElement, BuildingInfoProps>(
  ({ project, onUpdateProject, onSnackbar }, ref) => {
    const [buildingInfo, setBuildingInfo] = useState<BuildingInfoData>(blankBuildingInfo);
    const [originalInfo, setOriginalInfo] = useState<BuildingInfoData>(blankBuildingInfo);
    const [errors, setErrors] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(0);
    const [propertyTypeList, setPropertyTypeList] = useState<string[]>([]);

    useEffect(() => {
      if (project && project.attributes) {
        const projectInfo: BuildingInfoData = {
          name: project.attributes.name || '',
          address: project.attributes.address || '',
          city: project.attributes.city || '',
          state: project.attributes.state || '',
          zipCode: project.attributes.zipCode || '',
          squareFeet: formatSquareFeet(project.attributes.squareFeet) || '',
          yearBuilt: project.attributes.yearBuilt || '',
          services: project.attributes.services || [],
          propertyType: project.attributes.propertyType || '',
          childProperties: project.attributes.childProperties || [],
        };

        setBuildingInfo(projectInfo);
        setOriginalInfo(projectInfo);
        calculateProgress(projectInfo);
      }
    }, [project]);

    useEffect(() => {
      let isMounted = true;

      const fetchPropertyList = async () => {
        try {
          const propertyTypes = await projectServices.getPropertyTypes();
          if (isMounted) {
            setPropertyTypeList(propertyTypes);
          }
        } catch (error) {
          console.error('Failed to fetch property types:', error);
        }
      };

      fetchPropertyList();

      return () => {
        isMounted = false;
      };
    }, []);

    const calculateProgress = (info: BuildingInfoData) => {
      const totalFields = Object.keys(info).length;
      const filledFields = Object.entries(info).filter(([key, value]) => {
        if (key === 'services') {
          return value.length > 0;
        }
        return value !== '';
      }).length;
      setProgress((filledFields / totalFields) * 100);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const updatedInfo = { ...buildingInfo, [name]: value };
      setBuildingInfo(updatedInfo);
      setErrors({ ...errors, [name]: '' });
      calculateProgress(updatedInfo);
    };

    const validateForm = () => {
      let tempErrors: Partial<Record<keyof BuildingInfoData, any>> = {};
      tempErrors.name = buildingInfo.name ? '' : 'Building name is required';
      tempErrors.address = buildingInfo.address ? '' : 'Address is required';
      tempErrors.city = buildingInfo.city ? '' : 'City is required';
      tempErrors.state = buildingInfo.state ? '' : 'State is required';
      tempErrors.zipCode = /^\d{5}(-\d{4})?$/.test(buildingInfo.zipCode) ? '' : 'Invalid ZIP code';
      tempErrors.squareFeet = +buildingInfo.squareFeet > 0 ? '' : 'Invalid square footage';
      tempErrors.yearBuilt = /^\d{4}$/.test(buildingInfo.yearBuilt) ? '' : 'Invalid year';
      tempErrors.propertyType = buildingInfo.propertyType ? '' : 'Property type is required';

      setErrors(tempErrors);
      return Object.values(tempErrors).every((x) => x === '');
    };

    const hasChanges = useMemo(() => {
      return Object.keys(buildingInfo).some((key) => buildingInfo[key] !== originalInfo[key]);
    }, [buildingInfo, originalInfo]);

    const handleSubmit = async (event?: FormEvent) => {
      if (event) {
        event.preventDefault();
      }
      if (validateForm()) {
        if (!hasChanges) {
          // was "info" - add support to toast?
          onSnackbar('No changes to save', 'default');
          return;
        }

        const result = await onUpdateProject({ id: project.id, data: { ...project.attributes, ...buildingInfo } });
        if (result.success) {
          setIsEditing(false);
          setOriginalInfo(buildingInfo);
          setBuildingInfo(buildingInfo);

          if (result.updatedProject) {
            setBuildingInfo(result.updatedProject.attributes);
            setOriginalInfo(result.updatedProject.attributes);
          }
        }
      } else {
        onSnackbar('Please correct the errors before submitting', 'destructive');
      }
    };

    const handleEdit = () => {
      if (isEditing) {
        setBuildingInfo(originalInfo);
        setErrors({});
      }
      setIsEditing((v) => !v);
      setActiveStep(0);
    };

    const handleServiceChange = (newChecked: CheckedState, service: string) => {
      const updatedServices = newChecked
        ? [...buildingInfo.services, service]
        : buildingInfo.services.filter((s) => s !== service);

      const updatedInfo = { ...buildingInfo, services: updatedServices };
      setBuildingInfo(updatedInfo);
      calculateProgress(updatedInfo);
    };

    const steps = [
      { label: 'Basic Info', fields: ['name', 'propertyType'] },
      { label: 'Location', fields: ['address', 'city', 'state', 'zipCode'] },
      { label: 'Building Details', fields: ['squareFeet', 'yearBuilt'] },
      { label: 'Please pick your utility services', fields: ['services'] },
    ];

    const renderStepContent = (step: number) => (
      <div className="grid gap-4">
        {steps[step].fields.map((field) => (
          <div className="grid grid-cols-1" key={field}>
            {field !== 'services' ? (
              field === 'propertyType' ? (
                <>
                  <Select
                    name="propertyType"
                    value={buildingInfo.propertyType}
                    onValueChange={(newVal) => {
                      if (!newVal) return;
                      setBuildingInfo((prev) => ({ ...prev, propertyType: newVal }));
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Property Type" />
                    </SelectTrigger>
                    <SelectContent>
                      {propertyTypeList.map((propertyType) => (
                        <SelectItem key={propertyType} value={propertyType} className="cursor-pointer">
                          {propertyType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors[field] ? (
                    <p className="bg-destructive/25 text-destructive-foreground">{errors[field]}</p>
                  ) : null}
                </>
              ) : (
                <InputWrapper
                  label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                  inputStartAdornment={
                    <>
                      {field === 'name' && <HomeIcon className="stroke-foreground" />}
                      {field === 'address' && <Building2Icon className="stroke-foreground" />}
                      {field === 'city' && <Building2Icon className="stroke-foreground" />}
                      {field === 'state' && <MapPinIcon className="stroke-foreground" />}
                      {field === 'zipCode' && <MapPinIcon className="stroke-foreground" />}
                      {field === 'squareFeet' && <RulerIcon className="stroke-foreground" />}
                      {field === 'yearBuilt' && <CalendarIcon className="stroke-foreground" />}
                      {field === 'propertyType' && <BriefcaseBusinessIcon className="stroke-foreground" />}
                    </>
                  }
                  name={field}
                  value={buildingInfo[field as keyof BuildingInfoFormFields]}
                  onChange={handleInputChange}
                  error={!!errors[field as keyof BuildingInfoData]}
                  helperText={errors[field as keyof BuildingInfoData]}
                />
              )
            ) : (
              UTILITY_SERVICES.map((service) => (
                <div key={service}>
                  <Label className="flex items-center gap-2">
                    <Checkbox
                      checked={buildingInfo.services.includes(service)}
                      onCheckedChange={(checked) => handleServiceChange(checked, service)}
                      name={service}
                    />
                    <p>{service.charAt(0).toUpperCase() + service.slice(1)}</p>
                  </Label>
                </div>
              ))
            )}
          </div>
        ))}
      </div>
    );

    return (
      <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
        <div className="flex w-full flex-row items-center justify-start gap-2 px-4 pb-2">
          <h2 className="font-bold text-foreground">Building Information</h2>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" onClick={handleEdit}>
                {isEditing ? <XIcon className="stroke-destructive" /> : <PencilIcon className="stroke-primary" />}
              </Button>
            </TooltipTrigger>
            <TooltipContent>{isEditing ? 'Cancel Editing' : 'Edit Building Info'}</TooltipContent>
          </Tooltip>
        </div>

        <ScrollArea orientation="both" className="grow p-4">
          <Collapsible expanded={!isEditing}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {Object.entries(buildingInfo).map(([key, value]) => {
                if (key === 'childProperties') return null; // Skip rendering childProperties in the grid
                return (
                  <Card
                    key={key}
                    className="animate-zoom-in rounded-xl border-border/20 bg-background/5 shadow-lg backdrop-blur-sm"
                  >
                    <CardContent className="pt-6">
                      <div className="mb-4 flex flex-row items-center justify-start gap-2">
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoIcon className="h-4 w-4 text-muted-foreground/80" onClick={handleEdit} />
                          </TooltipTrigger>
                          <TooltipContent>{`Enter ${key.replace(/([A-Z])/g, ' $1').toLowerCase()} information`}</TooltipContent>
                        </Tooltip>
                        <p className="text-base font-bold text-muted-foreground/80">
                          {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                        </p>
                      </div>
                      <p className="text-lg text-foreground">{formatValue(key, value)}</p>
                    </CardContent>
                  </Card>
                );
              })}
              <ChildPropertiesCard childProperties={project.attributes.childProperties} />
            </div>
          </Collapsible>

          <Collapsible expanded={isEditing}>
            <form onSubmit={handleSubmit}>
              <Accordion
                type="single"
                value={`${activeStep}`}
                onValueChange={(newVal) => setActiveStep(+newVal)}
                className="border-border/20"
              >
                {steps.map((step, index) => (
                  <AccordionItem className="border-border/20" key={step.label} value={`${index}`}>
                    <AccordionTrigger className="text-foreground hover:text-foreground/90">
                      {step.label}
                    </AccordionTrigger>
                    <AccordionContent>
                      <div
                        className={cn({
                          'animate-fade-in duration-300': activeStep === index,
                        })}
                      >
                        {renderStepContent(index)}
                      </div>
                      <div className="mt-4 flex flex-row gap-2">
                        <Button
                          type="button"
                          disabled={activeStep === 0}
                          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                          className="bg-primary/90 text-primary-foreground hover:bg-primary"
                        >
                          Back
                        </Button>
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            if (activeStep === steps.length - 1) {
                              handleSubmit();
                            } else {
                              setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                          }}
                          className="border-border/20 bg-background/5 hover:bg-background/10"
                        >
                          {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                        </Button>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </form>
          </Collapsible>

          <div className="mt-6 w-full">
            <Tooltip>
              <TooltipTrigger asChild>
                <Progress thin value={progress} className="bg-background/10" />
              </TooltipTrigger>
              <TooltipContent>{`Building info is ${Math.round(progress)}% complete`}</TooltipContent>
            </Tooltip>
          </div>
        </ScrollArea>
      </div>
    );
  }
);
