import * as React from 'react';
import { useState, useEffect, ReactNode } from 'react';
import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Facebook, Twitter, Google } from '@shadcn/custom/BrandIcons';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import { Button } from '@shadcn/ui';
import { FormikInputWrapper } from '@shadcn/custom/FormikInputWrapper';

import authServices from 'app/services/auth-services';
import conedService from 'app/services/coned';
import { trackGoogleAnalyticsEvent } from 'app/utils/google-analytics';
import { SignUpCategory, SignUpCompletedAction } from 'app/utils/google-analytics/events/signUp';
import { LoginCategory, LoginCompletedAction } from 'app/utils/google-analytics/events/login';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const typeToConfig = {
  login: {
    text: 'LOG IN',
    authMethod: authServices.signIn,
  },
};

// ConEd OAuth configuration
const handleConEdRedirect = async () => {
  try {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const error = urlParams.get('error');
    const errorDesc = urlParams.get('error_description');

    // Check if this is a ConEd callback
    if (!code && !error) {
      return false;
    }

    // Verify state matches what we stored
    const storedState = sessionStorage.getItem('coned_state');
    if (state !== storedState) {
      throw new Error('Security verification failed - please try again');
    }

    // Clean up stored state
    sessionStorage.removeItem('coned_state');

    // Check for specific ConEd errors
    if (error) {
      switch (error) {
        case 'invalid_scope':
          throw new Error('Invalid authorization scope. Please contact support.');

        case 'invalid_request':
          if (errorDesc?.includes('Invalid RedirectURI')) {
            throw new Error('Invalid redirect configuration. Please contact support.');
          }
          throw new Error('Invalid request. Please try again.');

        case 'unauthorized_client':
          throw new Error('Application not authorized. Please contact support.');

        default:
          throw new Error(`Authorization failed: ${errorDesc || error}`);
      }
    }

    // Exchange code for tokens
    if (code) {
      const body = {
        authorizationCode: code,
      };

      await conedService.exchangeCodeForAccessToken({ body });
      return true;
    }
  } catch (error) {
    console.error('Error handling ConEd callback:', error);
    throw error;
  }

  return false;
};

export const Landing = ({ disableSmLogin, type }) => {
  const { tempToken } = useParams();
  const { setAuthToken } = useJumboAuth();
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState('');
  const [isProcessingCallback, setIsProcessingCallback] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const processConEdCallback = async () => {
      try {
        setIsProcessingCallback(true);
        const wasConEdCallback = await handleConEdRedirect();
        if (wasConEdCallback) {
          navigate('/account/profile', {
            state: { message: 'Successfully connected to ConEd!' },
          });
        }
      } catch (error) {
        console.error('Failed to process ConEd callback:', error);
        navigate('/account/profile', {
          state: { error: 'Failed to connect to ConEd. Please try again.' },
        });
      } finally {
        setIsProcessingCallback(false);
      }
    };

    processConEdCallback();
  }, [navigate]);

  const errorHandler = (error) => {
    if (error?.response) {
      const errors = error.response?.data?.errors;
      const errorObject = errors[0];
      const message = errorObject?.detail || 'Looks like there was an error. Please try again!';
      setServerMessage(message);
    }
  };

  const loginWithToken = async (tempToken) => {
    try {
      const currentUser = await authServices.getUserByTempToken(tempToken);
      if (currentUser?.attributes?.token) {
        setAuthToken(currentUser.attributes.token);
        setRefreshKey((prevKey) => prevKey + 1);
        trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (tempToken) {
      loginWithToken(tempToken);
    }
  }, [tempToken]);

  const config = typeToConfig[type || 'login'];

  const onGoogleSignIn = async (payload) => {
    setServerMessage('');
    try {
      const response = await authServices.signInWithGoogle(payload);
      if (response?.attributes?.token) {
        setAuthToken(response?.attributes?.token);
        setRefreshKey((prevKey) => prevKey + 1);
        if (!type) {
          trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
        }
        if (type === 'signup') {
          trackGoogleAnalyticsEvent(SignUpCategory, SignUpCompletedAction, window.location.pathname);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const onSubmit = async (email, password) => {
    setServerMessage('');

    try {
      const authMethod = config.authMethod;
      const response = await authMethod({ email, password });
      if (response?.attributes?.token) {
        setAuthToken(response?.attributes?.token);
        if (!type) {
          trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
        }
        if (type === 'signup') {
          trackGoogleAnalyticsEvent(SignUpCategory, SignUpCompletedAction, window.location.pathname);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const renderForm = (): ReactNode => (
    <div className="flex flex-col gap-4">
      {/*<GoogleLogin
        onSuccess={(credentialResponse) => onGoogleSignIn(credentialResponse)}
        size="large"
        text="continue_with"
        shape="rectangular"
        type="standard"
        logo_alignment="left"
        width="500px"
      />
      <div className="my-4 text-center">OR</div>
      */}

      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.email, data.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-4 text-left" noValidate autoComplete="off">
            <FormikInputWrapper label="Email" name="email" placeholder="you@company.com" />
            <FormikInputWrapper label="Password" name="password" placeholder="Password" type="password" />
            {serverMessage ? <p className="bg-destructive/25 text-destructive-foreground">{serverMessage}</p> : null}
            <Button className="w-full" type="submit" variant="default" size="lg" loading={isSubmitting}>
              {config.text}
            </Button>
            {disableSmLogin ? null : (
              <>
                <p className="mb-2">Or sign in with</p>
                <div className="mb-1 flex flex-row items-center gap-1">
                  <Button size="icon" aria-label="Facebook" className="bg-[#385196] hover:bg-[#385196]">
                    <Facebook />
                  </Button>
                  <Button size="icon" aria-label="Twitter" className="bg-[#00a8ff] hover:bg-[#00a8ff]">
                    <Twitter />
                  </Button>
                  <Button size="icon" aria-label="Google" className="bg-[#23272b] hover:bg-[#23272b]">
                    <Google />
                  </Button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );

  if (isProcessingCallback) {
    return (
      <AuthPageShell
        rightContent={
          <div className="flex items-center justify-center">
            <p className="text-lg">Processing ConEd authorization...</p>
          </div>
        }
      />
    );
  }

  return <AuthPageShell rightContent={renderForm()} />;
};
