import { ELECTRIC, SERVICE_METRICS } from 'app/utils/constants/utilityServices';
import type { MissingMonth } from 'app/components/DataAlert';
import type { TrendDataResponse, DocumentData, TrendDataPoint } from 'app/types/visualizations';

const getTrendDataByMonth = (
  documents: any,
  serviceType: string,
  weather: any,
  baseloads: any,
  degreeData: any,
  degreeDayType: string
): TrendDataPoint[] => {
  const hasWeather = !!weather;
  const hasBaseloads = !!baseloads && !!baseloads[serviceType];
  const hasDegreeData = !!degreeData;

  const trendData = documents[serviceType].months.map((month) => {
    const isOil = serviceType === 'oil';
    const [m, d, y] = isOil ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
    const monthDayYear = `${m}-${d}-${y}`;
    const monthYear = `${m}-${y}`;
    const result: any = {
      monthYear: isOil ? monthDayYear : monthYear,
      totalUsage: month[SERVICE_METRICS[serviceType]],
    };

    if (serviceType === ELECTRIC) {
      result.peakDemandKw = month['total_demand_kw'];
    }

    if (hasWeather && weather[monthYear]) {
      result.temperature = weather[monthYear]['avg'];
    }

    if (hasBaseloads) {
      result.baseload = baseloads[serviceType].baseload;
    }

    if (hasDegreeData && degreeData[monthYear]) {
      result.degreeDay = degreeData[monthYear][degreeDayType];
      result.degreeDayMetric = degreeDayType;
    }

    return result;
  });

  return trendData;
};

const getTrendDataByBillPeriod = (
  documents: any,
  serviceType: string,
  weather: any,
  baseloads: any,
  degreeData: any,
  degreeDataByBillingPeriod: any,
  degreeDayType: string
): TrendDataPoint[] => {
  const hasWeather = !!weather;
  const hasBaseloads = !!baseloads && !!baseloads[serviceType];
  const hasDegreeData = !!degreeData;
  const hasDegreeDataByBillingPeriod = !!degreeDataByBillingPeriod;
  const trendData = documents[serviceType].billingPeriods.map((billPeriodData) => {
    const isElectric = serviceType === ELECTRIC;
    const isOil = serviceType === 'oil';

    const [m, d, y] = isOil
      ? billPeriodData.billing_summary_date.split('-')
      : billPeriodData.billing_start_date.split('-');
    const [startMonth, startDay, startYear] = billPeriodData.billing_start_date.split('-');
    const [endMonth, endDay, endYear] = billPeriodData.billing_end_date.split('-');
    const monthDayYear = `${m}-${d}-${y}`;
    const startDate = `${startMonth}-${startDay}-${startYear}`;
    const endDate = `${endMonth}-${endDay}-${endYear}`;
    const billingPeriod = `${startDate}_${endDate}`;
    const monthYear = `${m}-${y}`;
    const meterName = billPeriodData.meter_name;
    const result: any = {
      monthYear: isElectric || isOil ? monthDayYear : monthYear,
      billingPeriod: billingPeriod,
      meterName: meterName,
      totalUsage: billPeriodData[SERVICE_METRICS[serviceType]],
    };

    if (serviceType === ELECTRIC) {
      result.peakDemandKw = billPeriodData['total_demand_kw'];
    }

    if (hasWeather && weather[monthYear]) {
      result.temperature = weather[monthYear]['avg'];
    }

    if (hasBaseloads) {
      result.baseload = baseloads[serviceType].baseload;
    }

    if (hasDegreeData && degreeData[monthYear]) {
      result.degreeDay = degreeData[monthYear][degreeDayType];
      result.degreeDayMetric = degreeDayType;
    }

    if (hasDegreeDataByBillingPeriod && degreeDataByBillingPeriod[billingPeriod]) {
      result.degreeDayByBillingPeriod = degreeDataByBillingPeriod[billingPeriod][degreeDayType];
      result.degreeDayMetricByBillingPeriod = degreeDayType;
    }

    return result;
  });

  return trendData;
};

export const getTrendData = (
  documents: any,
  baseloads: any,
  weather: any,
  degreeData: any,
  degreeDataByBillingPeriod: any,
  serviceType: string,
  degreeDayType: string
): TrendDataResponse => {
  if (!documents[serviceType]) return { trendData: [], trendDataByBillPeriod: [], missingMonths: [] };

  const monthData = documents[serviceType].months;

  const trendDataByMonth = getTrendDataByMonth(documents, serviceType, weather, baseloads, degreeData, degreeDayType);
  const trendDataByBillPeriod = getTrendDataByBillPeriod(
    documents,
    serviceType,
    weather,
    baseloads,
    degreeData,
    degreeDataByBillingPeriod,
    degreeDayType
  );

  const missingMonths = findMissingMonths(monthData, serviceType, trendDataByMonth);
  return { trendData: trendDataByMonth, trendDataByBillPeriod, missingMonths };
};

export const getTrendDataMeterReadings = (
  documents: any,
  baseloads: any,
  weather: any,
  degreeData: any,
  degreeDataByBillingPeriod: any,
  serviceType: string,
  degreeDayType: string
): TrendDataResponse => {
  if (!documents[serviceType]) return { trendData: [], trendDataByBillPeriod: [], missingMonths: [] };

  const monthData = documents[serviceType].months;

  const trendDataByMonth = getTrendDataByMonth(documents, serviceType, weather, baseloads, degreeData, degreeDayType);
  const trendDataByBillPeriod = getTrendDataByBillPeriod(
    documents,
    serviceType,
    weather,
    baseloads,
    degreeData,
    degreeDataByBillingPeriod,
    degreeDayType
  );

  const missingMonths = findMissingMonths(monthData, serviceType, trendDataByMonth);
  return { trendData: trendDataByMonth, trendDataByBillPeriod, missingMonths };
};

const findMissingMonths = (months: any[], serviceType: string, trendData?: any[]) => {
  const missingMonths: MissingMonth[] = [];
  const monthsInData = new Set();

  // Get all unique years from the data
  const years = new Set(
    months.map((month) => {
      const [m, , y] =
        serviceType === 'oil' ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
      return y;
    })
  );

  // Add all existing month-year combinations to a Set
  months.forEach((month) => {
    const [m, , y] =
      serviceType === 'oil' ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
    monthsInData.add(`${m}-${y}`);
  });

  // Check for months with missing or zero values in trendData
  if (trendData) {
    trendData.forEach((data) => {
      const monthYear = data.monthYear;

      // Check for missing total usage
      if (!data.totalUsage || data.totalUsage === 0) {
        if (!missingMonths.some((m) => m.monthYear === monthYear)) {
          missingMonths.push({
            monthYear,
            type: 'missing_total_usage',
          });
        }
      }

      // Check for missing peak demand (electric only)
      if (serviceType === ELECTRIC && (!data.peakDemandKw || data.peakDemandKw === 0)) {
        if (!missingMonths.some((m) => m.monthYear === monthYear)) {
          missingMonths.push({
            monthYear,
            type: 'missing_peak_demand',
          });
        }
      }
    });
  }

  // Check each month of each year for completely missing data
  years.forEach((year) => {
    for (let month = 1; month <= 12; month++) {
      const monthStr = month.toString().padStart(2, '0');
      const monthYear = `${monthStr}-${year}`;
      if (!monthsInData.has(monthYear) && !missingMonths.some((m) => m.monthYear === monthYear)) {
        missingMonths.push({
          monthYear,
          type: 'missing_month',
        });
      }
    }
  });

  return missingMonths.sort((a, b) => a.monthYear.localeCompare(b.monthYear));
};

export const getStartDate = (documents: any) => {
  return Object.values(documents as DocumentData).reduce(
    (earliest, doc) => {
      const { startDate } = doc;
      return startDate !== '' && startDate < earliest ? startDate : earliest;
    },
    Object.values(documents as DocumentData)[0].startDate
  );
};

export const getEndDate = (documents: any) => {
  return Object.values(documents as DocumentData).reduce(
    (latest, doc) => {
      const { endDate } = doc;
      return endDate !== '' && endDate > latest ? endDate : latest;
    },
    Object.values(documents as DocumentData)[0].endDate
  );
};

export const formatDateForInput = (dateString: string) => {
  if (!dateString) return '';

  const parts = dateString.split('-');
  const isYearFirst = parts[0].length === 4;
  let year, month, day;

  if (isYearFirst) {
    // Format: 2016-11-01
    [year, month, day] = parts;
  } else {
    // Format: 01-11-2016
    [month, day, year] = parts;
  }

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};
