import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import { LoaderCircleIcon, X, RefreshCw } from 'lucide-react';
import { forwardRef, useEffect, useState, useRef } from 'react';
import {
  ScrollArea,
  toastVariants,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  Card,
  Badge,
  Button,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@shadcn/ui';
import { ReportPreview } from './ReportPreview';

import projectServices from 'app/services/project-services';
import type { YearData } from 'app/types/penalty-data';
import { DataSource } from 'app/types/visualizations';
import type { PenaltyData } from 'app/types/penalty-data';
interface ReportsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const ReportBuilder = forwardRef<HTMLDivElement, ReportsProps>(({ projectId, onSnackbar }, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [yearsData, setYearsData] = useState<YearData[]>([]);
  const [yearsDataUtilityBills, setYearsDataUtilityBills] = useState<YearData[]>([]);
  const [yearsDataMeterReadings, setYearsDataMeterReadings] = useState<YearData[]>([]);
  const [selectedDateRanges, setSelectedDateRanges] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<DataSource>('utility-bills');
  const [refreshKey, setRefreshKey] = useState(0);
  const [showRefreshConfirm, setShowRefreshConfirm] = useState(false);
  const [reportName, setReportName] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const reportBuilderRef = useRef<any>(null);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchData = async () => {
      try {
        let utilityBillsEmissions: PenaltyData | null = null;
        let meterReadingsEmissions: PenaltyData | null = null;

        // Fetch utility bills emissions
        try {
          utilityBillsEmissions = await projectServices.getEmissions({
            id: projectId,
            query: { dataSource: 'utility-bills' },
          });
          setYearsDataUtilityBills(utilityBillsEmissions?.estimatedAnnual?.yearsData || []);
        } catch (error: any) {
          // Only log warning for 400 errors (no data)
          if (error?.response?.status === 400) {
            console.warn('No data available for utility bills emissions');
            setYearsDataUtilityBills([]);
          } else {
            console.error('Error fetching utility bills emissions:', error);
            onSnackbar('Failed to fetch utility bills data', 'destructive');
          }
        }

        // Fetch meter readings emissions
        try {
          meterReadingsEmissions = await projectServices.getEmissions({
            id: projectId,
            query: { dataSource: 'energy-star' },
          });
          setYearsDataMeterReadings(meterReadingsEmissions?.estimatedAnnual?.yearsData || []);
        } catch (error: any) {
          // Only log warning for 400 errors (no data)
          if (error?.response?.status === 400) {
            console.warn('No data available for meter readings emissions');
            setYearsDataMeterReadings([]);
          } else {
            console.error('Error fetching meter readings emissions:', error);
            onSnackbar('Failed to fetch meter readings data', 'destructive');
          }
        }

        if (isMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        if (isMounted) {
          setIsLoading(false);
          onSnackbar('Failed to fetch report data', 'destructive');
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [projectId]);

  // Update date range selection whenever data source changes
  useEffect(() => {
    const currentYearsData = dataSource === 'utility-bills' ? yearsDataUtilityBills : yearsDataMeterReadings;
    setYearsData(currentYearsData || []);
  }, [dataSource, yearsDataUtilityBills, yearsDataMeterReadings]);

  const availableYears =
    yearsData
      ?.map((yearData) => {
        // Calculate which year has more months in the date range
        const startDate = new Date(yearData.startDate);
        const endDate = new Date(yearData.endDate);

        // Get months in each year of the range
        const yearMonths: { [key: string]: number } = {};
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          const year = currentDate.getFullYear().toString();
          yearMonths[year] = (yearMonths[year] || 0) + 1;
          currentDate.setMonth(currentDate.getMonth() + 1);
        }

        // Find the year with most months
        const dominantYear = Object.entries(yearMonths).reduce((a, b) => (a[1] > b[1] ? a : b))[0];

        return {
          year: Number(dominantYear),
          dateRange: `${new Date(yearData.startDate).toLocaleDateString()} - ${new Date(yearData.endDate).toLocaleDateString()}`,
        };
      })
      .sort((a, b) => b.year - a.year) || [];

  const handleDateRangeSelect = (dateRange: string) => {
    setSelectedDateRanges((prev) => {
      if (prev.includes(dateRange)) {
        return prev.filter((dr) => dr !== dateRange);
      }
      return [...prev, dateRange];
    });
  };

  const removeDateRange = (dateRangeToRemove: string) => {
    setSelectedDateRanges((prev) => prev.filter((dateRange) => dateRange !== dateRangeToRemove));
  };

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-6">
        <h2 className="font-bold">Report Builder</h2>
      </div>

      <div className="relative flex grow flex-col">
        <Card className="mb-6 rounded-lg border border-border bg-card p-6 shadow-sm">
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-8">
              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium text-muted-foreground">Report Name:</Label>
                <Input
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  placeholder="Enter report name"
                  className="w-[200px]"
                />
              </div>

              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium text-muted-foreground">Data Source:</Label>
                <Select
                  value={dataSource}
                  onValueChange={(value) => {
                    setDataSource(value as DataSource);
                    setSelectedDateRanges([]);
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select data source" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="utility-bills">Utility Bills</SelectItem>
                    <SelectItem value="energy-star">Meter Readings</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium text-muted-foreground">Date Range:</Label>
                <Select value="" onValueChange={handleDateRangeSelect}>
                  <SelectTrigger className="w-[300px]">
                    <SelectValue placeholder="Select 12 month date range" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableYears
                      .filter((yearData) => !selectedDateRanges.includes(yearData.dateRange))
                      .map((yearData, index) => (
                        <SelectItem key={yearData.year - index} value={yearData.dateRange}>
                          {yearData.dateRange}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="ml-auto flex items-center gap-3">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <Button
                          onClick={() => {
                            if (showPreview) {
                              setShowRefreshConfirm(true);
                            } else {
                              setRefreshKey((prev) => prev + 1);
                              setShowPreview(true);
                            }
                          }}
                          className="flex items-center gap-2"
                          disabled={!reportName.trim()}
                        >
                          <RefreshCw className="h-4 w-4" />
                          Generate Report Preview
                        </Button>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {!reportName.trim()
                        ? 'Please enter a report name to generate preview'
                        : 'Generate a new report preview'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>

            {selectedDateRanges.length > 0 && (
              <div className="flex flex-wrap items-center gap-2">
                <Label className="text-sm font-medium text-muted-foreground">Selected Date Ranges:</Label>
                {selectedDateRanges.map((dateRange) => (
                  <Badge key={dateRange} variant="secondary" className="flex items-center gap-1">
                    {dateRange}
                    <X
                      className="h-3 w-3 cursor-pointer hover:text-destructive"
                      onClick={() => removeDateRange(dateRange)}
                    />
                  </Badge>
                ))}
              </div>
            )}
          </div>
        </Card>

        <ScrollArea orientation="vertical" className={cn('grow')}>
          <div className="p-4">
            {showPreview && (
              <ReportPreview
                ref={reportBuilderRef}
                key={refreshKey}
                projectId={projectId}
                onClose={() => {}}
                selectedDateRanges={selectedDateRanges}
                reportName={reportName}
                dataSource={dataSource}
              />
            )}
          </div>
        </ScrollArea>
      </div>

      <AlertDialog open={showRefreshConfirm} onOpenChange={setShowRefreshConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Refresh Report Preview</AlertDialogTitle>
            <AlertDialogDescription>
              Generating Report Preview will lose any edits to the current preview. Do you wish to proceed?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setRefreshKey((prev) => prev + 1);
                setShowPreview(true);
                setShowRefreshConfirm(false);
              }}
            >
              Proceed
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
});
