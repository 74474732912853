import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsToolTip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { BarChartIcon } from 'lucide-react';
import { NoDataFound } from '../project/NoDataFound';
import { CollapsibleChart } from './CollapsibleChart';
import { WeatherProps } from 'app/types/visualizations';
import { TrendDataPoint } from 'app/types/visualizations';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
  createTemperatureYAxisProps,
} from './shared/chartProps';

interface PeakDemandChartProps {
  utilityBillsData: TrendDataPoint[];
  weather: WeatherProps;
}

export const PeakDemandChart = ({ utilityBillsData, weather }: PeakDemandChartProps) => {
  const renderChart = () => {
    if (utilityBillsData.length === 0) {
      return (
        <NoDataFound
          message="No peak demand data available"
          submessage="There is no peak demand data to display for this time period"
          icon={<BarChartIcon className="h-8 w-8 text-muted-foreground" />}
        />
      );
    }

    return (
      <div className="h-[500px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={utilityBillsData} margin={{ top: 20, right: 50, left: 50, bottom: 60 }}>
            <CartesianGrid {...sharedCartesianGridProps} />
            <XAxis {...sharedXAxisProps} />
            <YAxis {...createYAxisProps('Energy Consumption (kWh)')} />
            {weather && <YAxis {...createTemperatureYAxisProps()} />}
            <RechartsToolTip {...sharedTooltipProps} />
            <Legend {...sharedLegendProps} />
            <Bar
              yAxisId="left"
              dataKey="peakDemandKw"
              fill="rgb(var(--chart-energy-rgb))"
              name="Peak Demand Consumption (kWh)"
              radius={[4, 4, 0, 0]}
            />
            {weather && (
              <Line
                unit=" °F"
                name="Temperature"
                yAxisId="right"
                type="monotone"
                dataKey="temperature"
                stroke="rgb(var(--chart-temperature-rgb))"
                strokeWidth={2}
                dot={{ fill: 'rgb(var(--chart-temperature-rgb))', r: 4 }}
                activeDot={{ r: 6, fill: 'rgb(var(--chart-temperature-rgb))' }}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <CollapsibleChart title="Peak Demand Consumption">
      <div className="mb-6 space-y-2"></div>
      {renderChart()}
    </CollapsibleChart>
  );
};
