import type { PropertyMetrics, FlattenedAlert } from 'app/services/energy-star-services';
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner';
import { AlertTriangle } from 'lucide-react';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';

type EnergyStarScoreCardProps = {
  scores?: { [dateRange: string]: PropertyMetrics };
  noScoreReason?: FlattenedAlert[];
  theme: any;
};

export const EnergyStarScoreCard = ({ scores, noScoreReason, theme }: EnergyStarScoreCardProps) => (
  <CollapsibleChart title="Energy Star Score">
    <div className="overflow-hidden rounded-lg border border-border/10 bg-background/40 p-6">
      {scores === undefined && noScoreReason === undefined ? (
        <div className="flex h-24 items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : scores?.score ? (
        <div className="flex items-center gap-4">
          <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary text-3xl font-bold text-primary-foreground">
            {/* {Math.round(Object.values(scores)[Object.values(scores).length - 1].score)} */}
          </div>
          <div>
            <p className="text-md font-medium text-foreground/90">Energy Star Score (as of November 2024)</p>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="flex items-center gap-3">
            <AlertTriangle className="h-5 w-5 text-amber-500" />
            <p className="text-lg font-medium text-foreground/90">No Energy Star score available</p>
          </div>

          {noScoreReason && noScoreReason.length > 0 && (
            <div className="space-y-4">
              <p className="text-xs font-medium uppercase tracking-wider text-foreground/80">Reasons</p>
              {noScoreReason.map((reason, index) => (
                <div key={index} className="overflow-hidden rounded-lg border border-border/10">
                  <div
                    className="border-b border-border/10 bg-muted/30 p-4 text-foreground/90"
                    dangerouslySetInnerHTML={{ __html: reason.name }}
                  />
                  {reason.description && (
                    <div
                      className="p-4 text-sm leading-relaxed text-foreground/80"
                      dangerouslySetInnerHTML={{ __html: reason.description }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  </CollapsibleChart>
);
