import { AlertTriangleIcon, ChevronRightIcon } from 'lucide-react';
import { cn } from '@shadcn/utils';
import { useState } from 'react';

export interface MissingMonth {
  monthYear: string;
  type: 'missing_month' | 'missing_peak_demand' | 'missing_total_usage';
  serviceType?: string;
}

export interface DuplicateData {
  type: 'billing_period' | 'amount_due';
  documents: {
    id: string;
    fileName: string;
    value: string;
  }[];
}

export interface MissingData {
  [serviceType: string]: MissingMonth[];
}

interface DataAlertProps {
  variant: 'missing' | 'duplicate';
  data: MissingData | DuplicateData[];
}

const variantStyles = {
  missing: {
    container: 'border-amber-400/30 bg-amber-400/5',
    text: 'text-amber-400',
    textMuted: 'text-amber-400/80',
    badge: {
      missing_month: 'bg-rose-500/15 text-rose-400 font-medium',
      missing_peak_demand: 'bg-amber-500/15 text-amber-400 font-medium',
      missing_total_usage: 'bg-orange-400/15 text-orange-400 font-medium',
    },
    legend: [
      { color: 'bg-rose-400', text: 'text-rose-400', label: 'Missing All Data' },
      { color: 'bg-amber-400', text: 'text-amber-400', label: 'Missing Peak Demand Only' },
      { color: 'bg-orange-400', text: 'text-orange-400', label: 'Missing Usage Data' },
    ],
  },
  duplicate: {
    container: 'border-blue-400/30 bg-blue-400/5',
    text: 'text-blue-400',
    textMuted: 'text-blue-400/80',
    badge: {
      billing_period: 'bg-blue-500/15 text-blue-400 font-medium',
      amount_due: 'bg-blue-500/15 text-blue-400 font-medium',
    },
    legend: [{ color: 'bg-blue-400', text: 'text-blue-400', label: 'Potential Duplicate Data' }],
  },
};

const groupMissingMonthsByYear = (missingMonths: MissingMonth[]) => {
  return missingMonths.reduce(
    (acc, month) => {
      const [m, y] = month.monthYear.split('-');
      if (!acc[y]) {
        acc[y] = [];
      }
      acc[y].push(month);
      return acc;
    },
    {} as { [key: string]: MissingMonth[] }
  );
};

export const DataAlert = ({ variant, data }: DataAlertProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (
    (variant === 'missing' && Object.keys(data as MissingData).length === 0) ||
    (variant === 'duplicate' && (data as DuplicateData[]).length === 0)
  ) {
    return null;
  }

  const styles = variantStyles[variant];
  const isMissing = variant === 'missing';

  const renderMissingData = (missingData: MissingData) => {
    return Object.entries(missingData).map(([serviceType, months]) => {
      if (months.length === 0) return null;

      const groupedData = groupMissingMonthsByYear(months);

      return (
        <div key={serviceType} className="mb-4 last:mb-0">
          <h4 className={cn('mb-2 font-medium capitalize', styles.text)}>{serviceType} Service</h4>
          {Object.entries(groupedData).map(([year, months]) => (
            <div key={year} className="rounded-lg bg-black/10 p-3">
              <div className="flex flex-wrap gap-2">
                {months.map((month) => (
                  <span
                    key={month.monthYear}
                    className={cn('rounded-full px-3 py-1 text-xs', styles.badge[month.type])}
                  >
                    {month.monthYear.split('-')[0] + ' ' + month.monthYear.split('-')[1]}
                    {month.type === 'missing_month' && ' (No Data)'}
                    {month.type === 'missing_peak_demand' && ' (No Peak Demand)'}
                    {month.type === 'missing_total_usage' && ' (No Usage Data)'}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className={cn('mb-8 rounded-lg border p-4', styles.container)}>
      <div className="flex cursor-pointer select-none items-center gap-2" onClick={() => setIsExpanded(!isExpanded)}>
        <ChevronRightIcon className={cn('h-5 w-5 transition-transform', styles.text, isExpanded && 'rotate-90')} />
        <AlertTriangleIcon className={cn('h-5 w-5', styles.text)} />
        <h4 className={cn('font-semibold', styles.text)}>
          {isMissing ? 'Missing Data Alert' : 'Duplicate Data Alert'}
        </h4>
      </div>

      <div
        className={cn(
          'grid transition-all duration-200 ease-in-out',
          isExpanded ? 'mt-4 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
        )}
      >
        <div className="overflow-hidden">
          <p className={cn('mb-4 text-sm', styles.textMuted)}>
            {isMissing
              ? 'The following months have data issues:'
              : 'The following documents may contain duplicate data:'}
          </p>

          <div className="flex flex-col gap-4">
            {isMissing
              ? renderMissingData(data as MissingData)
              : // Duplicate Data View
                (data as DuplicateData[]).map((duplicate, index) => (
                  <div key={index} className="rounded-lg bg-black/10 p-3">
                    <span className={cn('mb-2 block text-sm', styles.textMuted)}>
                      {duplicate.type === 'billing_period' ? 'Same Billing Period' : 'Same Amount Due'}:{' '}
                      {duplicate.documents[0].value}
                    </span>
                    <div className="flex flex-wrap gap-2">
                      {duplicate.documents.map((doc) => (
                        <span
                          key={doc.id}
                          className={cn('rounded-full px-3 py-1 text-xs', styles.badge[duplicate.type])}
                        >
                          {doc.fileName}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
          </div>

          <div className="mt-4 flex gap-4 text-xs">
            {styles.legend.map(({ color, text, label }) => (
              <div key={label} className="flex items-center gap-2">
                <span className={cn('h-2 w-2 rounded-full', color)}></span>
                <span className={text}>{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
