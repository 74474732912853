import React, { useEffect, useState } from 'react';
import { LoaderCircleIcon, TrashIcon, RefreshCwIcon, SaveIcon, PencilIcon, XIcon, ArrowLeftIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Accordion,
  AccordionTrigger,
  AccordionItem,
  AccordionContent,
  ScrollArea,
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@shadcn/ui';
import { useToast } from '@shadcn/hooks/use-toast';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import documentServices, { UploadedDocument } from 'app/services/document-services';

import { DocumentViewer } from './DocumentViewer';

export const DocumentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [document, setDocument] = useState<UploadedDocument | null>(null);
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(true);

  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchDocument = async () => {
      try {
        return await documentServices.getById(id);
      } catch (error) {
        console.error('Error fetching document:', error);
        toast({
          description: 'Failed to fetch document. Please try again.',
          variant: 'destructive',
        });
      }
    };

    fetchDocument().then((fetchedDocument) => {
      if (isMounted) {
        setDocument(fetchedDocument);
        setEditedData(JSON.parse(JSON.stringify(fetchedDocument.attributes.detectedData)));
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id, toast]);

  const handleEdit = (field: string) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = async (field: string): Promise<void> => {
    if (!document) {
      return;
    }
    try {
      const newData = JSON.parse(JSON.stringify(document.attributes.detectedData));
      const fieldParts = field.split('.');
      let currentObj = newData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        currentObj = currentObj[fieldParts[i]];
      }
      currentObj[fieldParts[fieldParts.length - 1]] = editedData[field];

      await documentServices.update({ id, data: { detectedData: newData } });

      setDocument({
        ...document,
        attributes: {
          ...document.attributes,
          detectedData: newData,
        },
      });
      setEditMode({ ...editMode, [field]: false });
      toast({
        title: 'Success',
        description: 'Document updated successfully.',
      });
    } catch (error) {
      console.error('Error updating document:', error);
      toast({
        title: 'Error',
        description: 'Failed to update the document. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleChange = (field: string, value: string) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleReprocess = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const updatedDocument = await documentServices.reprocess(id);
      setDocument(updatedDocument);
      setEditedData({});
      toast({
        title: 'Success',
        description: 'Document queued for reprocessing.',
      });
    } catch (error) {
      console.error('Error reprocessing document:', error);
      toast({
        title: 'Error',
        description: 'Failed to reprocess document. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await documentServices.delete(id);
      navigate('/dashboard/project');
      toast({
        title: 'Success',
        description: 'Document deleted successfully.',
      });
    } catch (error) {
      console.error('Error deleting document:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the document. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const renderField = (field: string, value: string) => {
    const label = field.split('.')?.pop()?.replace(/_/g, ' ');
    return (
      <InputWrapper
        key={field}
        className="grow"
        value={
          // leftover from previously duplicated input fields, should be cleaned up
          editMode[field]
            ? (editedData[field] ??
              (document?.attributes?.detectedData ? getNestedValue(document.attributes.detectedData, field) : '') ??
              '')
            : value
        }
        readOnly={!editMode[field]}
        onChange={(e) => handleChange(field, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSave(field);
          }
        }}
        label={label}
        inputEndAdornment={
          editMode[field] ? (
            <div className="flex flex-row gap-2">
              <Button
                size="icon"
                variant="ghost"
                className="h-auto w-auto p-2 focus-visible:ring-offset-0"
                onClick={() => handleSave(field)}
              >
                <SaveIcon className="h-5 w-5 stroke-primary" />
              </Button>
              <Button
                size="icon"
                variant="ghost"
                className="h-auto w-auto p-2 focus-visible:ring-offset-0"
                onClick={() => setEditMode({ ...editMode, [field]: false })}
              >
                <XIcon className="h-5 w-5" />
              </Button>
            </div>
          ) : (
            <Button
              size="icon"
              className="h-auto w-auto p-2 focus-visible:ring-offset-0"
              variant="ghost"
              onClick={() => handleEdit(field)}
            >
              <PencilIcon className="size-5 self-center stroke-primary" />
            </Button>
          )
        }
      />
    );
  };

  // data: detectedData
  const renderMetadataFields = (data: Record<string, any>, prefix = '') => {
    return Object.entries(data).map(([key, value]) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        return (
          <AccordionItem value={fullKey} key={fullKey}>
            <AccordionTrigger showChevron className="text-base uppercase">
              {key.replace(/_/g, ' ')}
            </AccordionTrigger>
            <AccordionContent asChild className="flex flex-col gap-4 px-1 pl-4">
              {renderMetadataFields(value, fullKey)}{' '}
            </AccordionContent>
          </AccordionItem>
        );
      } else {
        return renderField(fullKey, value);
      }
    });
  };

  const getNestedValue = (obj: Record<string, any>, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <ResizablePanelGroup direction="horizontal" className="flex flex-row overflow-hidden">
      <ResizablePanel className="flex grow flex-col" defaultSize={70}>
        <div className="flex items-center gap-2 p-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={() => navigate(-1)} className="flex items-center gap-2">
                  <ArrowLeftIcon className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Back</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <h6 className="text-lg">{document?.attributes.fileName}</h6>
        </div>
        <ScrollArea className="w-full p-4 [&>[data-radix-scroll-area-viewport]]:items-center">
          <DocumentViewer url={document?.url} />
        </ScrollArea>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel className="flex flex-col gap-2">
        <h6 className="ml-4 text-lg">Document Metadata</h6>
        <ScrollArea className="px-4">
          {document && document.attributes.detectedData ? (
            <Accordion type="multiple">{renderMetadataFields(document.attributes.detectedData)}</Accordion>
          ) : null}
          <div className="mt-6 flex flex-row gap-2">
            <Button variant="outline" onClick={handleReprocess}>
              <RefreshCwIcon /> Reprocess
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              <TrashIcon /> Delete
            </Button>
          </div>
        </ScrollArea>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
