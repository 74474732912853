import { useEffect, useState } from 'react';
import type { Task } from '../types/task';
import type { ProjectInfoData } from '../services/project-services';

export const useTasks = (authUser: any | null, projects?: ProjectInfoData[], energyStarProperties?: any[]) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;

  useEffect(() => {
    const loadTasks = async () => {
      const newTasks: Task[] = [];

      if (!energyStarAccountId) {
        newTasks.push({
          id: 'connect-energy-star',
          title: 'Connect Energy Star Portfolio Manager',
          subtitle: 'Share your property data with Cosmos to automatically analyze your utility data.',
          description: `
# How to Share Properties in Portfolio Manager

## 1. Connect with Contacts
- Click "Contacts" in the top right corner
- Select "Add New Contacts/Connections"
- Search for Cosmos by organization name
- Send a connection request
- Wait for Cosmos to accept your request

## 2. Share Properties
### Start Sharing
- Go to the Sharing tab
- Click "Share (or Edit Access to) a Property"
- Select the properties you want to share
  - Choose single or multiple properties
  - Use filters if needed

### Choose Sharing Permissions
- Select "Full Access" so Cosmos can view and edit property data

### Select Contacts
- Select Cosmos from your list of contacts
- Set individual or bulk permission levels

**Pro Tip:** As the Property Data Administrator, only you can delete or transfer property ownership.`,
        });
      } else {
        try {
          const projectAddresses = new Set(projects?.map((p) => p.attributes.name?.toLowerCase().trim()));
          const unsyncedProperties = (energyStarProperties || []).filter(
            (prop) => !projectAddresses.has(prop.name?.toLowerCase().trim())
          );

          if (unsyncedProperties.length > 0) {
            newTasks.push({
              id: 'sync-energy-star',
              title: 'Sync Energy Star Properties',
              subtitle: `You have Energy Star ${unsyncedProperties.length === 1 ? 'property' : 'properties'} that need to be synced with Cosmos.`,
              description: 'Click to view and sync your Energy Star properties with Cosmos.',
            });
          }
        } catch (error) {
          console.error('Error loading Energy Star properties:', error);
        }
      }

      setTasks(newTasks);
    };

    loadTasks();
  }, [energyStarAccountId, projects, energyStarProperties]);

  return tasks;
};
