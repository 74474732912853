import { Building2, TrendingDown, DollarSign, AlertTriangle, LucideIcon } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { Theme } from 'app/types/theme';
import { formatNumber, formatCurrency } from 'app/utils/formatters';
import { PenaltyData } from 'app/types/penalty-data';
import { DataSource } from 'app/types/visualizations';
import { ProjectInfoData } from 'app/services/project-services';

interface InfoCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  theme: Theme;
  colorIndex?: number;
}

interface EmissionsSummaryProps {
  emissionsFromUtilityBills?: PenaltyData | null;
  emissionsFromMeterReadings?: PenaltyData | null;
  dataSource: DataSource;
  project: ProjectInfoData;
  theme: Theme;
}

const InfoCard = ({ icon: Icon, title, value, theme, colorIndex = 0 }: InfoCardProps) => {
  return (
    <Card className="group relative rounded-xl border-border/10 bg-white/5 backdrop-blur-sm transition-all duration-300 ease-in-out hover:border-primary/20 hover:bg-white/10 hover:shadow-[0_2px_8px_rgba(124,58,237,0.15)]">
      <CardHeader>
        <CardTitle className="flex flex-row items-center gap-2 text-lg">
          <Icon color={theme.text.highlight} className="h-5 w-5 transition-transform group-hover:scale-110" />
          <span className="text-base font-medium text-muted-foreground">{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent
        className="text-2xl font-bold tracking-tight transition-colors"
        style={{
          color: theme.chart.colors[colorIndex],
        }}
      >
        {value}
      </CardContent>
    </Card>
  );
};

export const EmissionsSummary = ({
  emissionsFromUtilityBills,
  emissionsFromMeterReadings,
  dataSource,
  project,
  theme,
}: EmissionsSummaryProps) => {
  const data =
    dataSource === 'utility-bills'
      ? emissionsFromUtilityBills
      : dataSource === 'energy-star'
        ? emissionsFromMeterReadings
        : emissionsFromUtilityBills;

  const squareFeet = project?.attributes?.squareFeet;
  const annualThresholdCO2 = data?.annualThresholdCO2;
  const estimatedEmissions = data?.estimatedAnnual.emissions;
  const estimatedPenalty = data?.estimatedAnnual.penalty;

  return (
    <div className="mb-8 grid animate-fade-up-in grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
      <InfoCard
        icon={Building2}
        title="Building Size"
        value={squareFeet ? `${formatNumber(squareFeet)} sq ft` : '—'}
        theme={theme}
        colorIndex={0}
      />
      <InfoCard
        icon={TrendingDown}
        title="Annual CO₂ Threshold"
        value={annualThresholdCO2 ? `${formatNumber(annualThresholdCO2)} tCO₂` : '—'}
        theme={theme}
        colorIndex={1}
      />
      <InfoCard
        icon={AlertTriangle}
        title="Est. Annual Emissions"
        value={estimatedEmissions ? `${formatNumber(estimatedEmissions)} tCO₂` : '—'}
        theme={theme}
        colorIndex={2}
      />
      <InfoCard
        icon={DollarSign}
        title="Est. Annual Penalty"
        value={estimatedPenalty ? formatCurrency(estimatedPenalty) : '—'}
        theme={theme}
        colorIndex={3}
      />
    </div>
  );
};
