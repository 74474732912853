import { FileTextIcon, BarChartIcon } from 'lucide-react';
import { ScrollArea } from '@shadcn/ui';
import { DataAlert } from 'app/components/DataAlert';
import { InsightCardsPanel } from 'app/components/panels/InsightCardsPanel';
import { UsageTrendChart } from 'app/components/charts/UsageTrendChart';
import { UsageDegreeChart } from 'app/components/charts/UsageDegreeChart';
import { PeakDemandChart } from 'app/components/charts/PeakDemandChart';
import { EmissionsSummary } from 'app/components/emissions/EmissionsSummary';
import { EnergyStarScoreCard } from 'app/components/energy-star/EnergyStarScoreCard';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { getUsageUnit } from 'app/utils/formatters';
import { ELECTRIC } from 'app/utils/constants/utilityServices';
import { DataSource } from 'app/types/visualizations';
import { PenaltyData } from 'app/types/penalty-data';
import { Documents, WeatherProps } from 'app/types/visualizations';
import { PropertyMetrics, FlattenedAlert } from 'app/services/energy-star-services';
import Last12Months from 'app/pages/project/tabs/analysis/Last12Months';
import UsageSummaryTables from 'app/pages/project/tabs/analysis/UsageSummaryTables';

interface ServiceContentProps {
  serviceType: string;
  dataSource: DataSource;
  documents: Documents;
  meterReadings: Documents;
  trendDataByService: any;
  baseloads: any;
  meterReadingsBaseloads: any;
  baseTemperature: number;
  isUpdatingDegreeData: boolean;
  weather: WeatherProps;
  onBaseTemperatureChange: (value: number[]) => void;
  project: any;
  emissionsFromUtilityBills: PenaltyData | null;
  emissionsFromMeterReadings: PenaltyData | null;
  isEmissionsLoading: boolean;
  projectId: string;
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
  noScoreReason?: FlattenedAlert[];
  theme: any;
}

export const ServiceContent = ({
  serviceType,
  dataSource,
  documents,
  meterReadings,
  trendDataByService,
  baseloads,
  meterReadingsBaseloads,
  baseTemperature,
  isUpdatingDegreeData,
  weather,
  onBaseTemperatureChange,
  project,
  emissionsFromUtilityBills,
  emissionsFromMeterReadings,
  isEmissionsLoading,
  projectId,
  energyStarScoreByDateRange,
  noScoreReason,
  theme,
}: ServiceContentProps) => {
  const renderDataSourceSection = (title: string, icon: React.ReactNode, children: React.ReactNode) => (
    <CollapsibleChart title={title}>{children}</CollapsibleChart>
  );

  if (serviceType === 'All') {
    return (
      <div>
        <EmissionsSummary
          dataSource={dataSource}
          emissionsFromUtilityBills={emissionsFromUtilityBills}
          emissionsFromMeterReadings={emissionsFromMeterReadings}
          project={project}
          theme={theme}
        />
        <ScrollArea orientation="vertical" className="grow p-4">
          <UsageSummaryTables
            dataSource={dataSource}
            projectId={projectId}
            emissionsFromUtilityBills={emissionsFromUtilityBills}
            emissionsFromMeterReadings={emissionsFromMeterReadings}
            formattedMeterReadings={meterReadings}
            formattedDocuments={documents}
            isLoading={isEmissionsLoading}
            energyStarScoreByDateRange={energyStarScoreByDateRange}
          />
          {dataSource === 'energy-star' && (
            <EnergyStarScoreCard scores={energyStarScoreByDateRange} noScoreReason={noScoreReason} theme={theme} />
          )}
        </ScrollArea>
      </div>
    );
  }

  return (
    <div>
      <DataAlert
        variant="missing"
        data={{
          [serviceType]: trendDataByService[serviceType].cdd.missingMonths,
        }}
      />
      <InsightCardsPanel
        serviceType={serviceType}
        documents={documents}
        meterReadings={meterReadings}
        baseloads={baseloads}
        renderLoadMetric={getUsageUnit}
        selectedDataSource={dataSource as DataSource}
      />

      {dataSource === 'utility-bills' &&
        renderDataSourceSection(
          'Utility Bills',
          <FileTextIcon className="h-5 w-5 text-primary" />,
          <>
            <UsageTrendChart
              utilityBillsData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
              meterReadingsData={[]}
              units={getUsageUnit(serviceType)}
            />
            <UsageDegreeChart
              coolingDegreeData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
              heatingDegreeData={trendDataByService[serviceType].hdd.trendDataByBillPeriod}
              meterReadingsCddData={[]}
              meterReadingsHddData={[]}
              baseloads={baseloads}
              meterReadingsBaseloads={meterReadingsBaseloads}
              serviceType={serviceType}
              baseTemperature={baseTemperature}
              onBaseTemperatureChange={onBaseTemperatureChange}
              isUpdatingDegreeData={isUpdatingDegreeData}
              renderLoadMetric={getUsageUnit}
            />
            {serviceType === ELECTRIC && (
              <PeakDemandChart
                utilityBillsData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
                weather={weather}
              />
            )}
          </>
        )}

      {dataSource === 'energy-star' &&
        renderDataSourceSection(
          'Meter Readings',
          <BarChartIcon className="h-5 w-5 text-primary" />,
          <>
            <UsageTrendChart
              utilityBillsData={[]}
              meterReadingsData={trendDataByService[serviceType].meterReadingsCdd.trendDataByBillPeriod}
              units={getUsageUnit(serviceType)}
            />
            <UsageDegreeChart
              coolingDegreeData={[]}
              heatingDegreeData={[]}
              meterReadingsCddData={trendDataByService[serviceType].meterReadingsCdd.trendDataByBillPeriod}
              meterReadingsHddData={trendDataByService[serviceType].meterReadingsHdd.trendDataByBillPeriod}
              baseloads={baseloads}
              meterReadingsBaseloads={meterReadingsBaseloads}
              serviceType={serviceType}
              baseTemperature={baseTemperature}
              onBaseTemperatureChange={onBaseTemperatureChange}
              isUpdatingDegreeData={isUpdatingDegreeData}
              renderLoadMetric={getUsageUnit}
            />
          </>
        )}
    </div>
  );
};
