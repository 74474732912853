import { useState } from 'react';
import { ChevronDownIcon } from 'lucide-react';
import { cn } from '@shadcn/utils';

interface CollapsibleChartProps {
  title: string;
  children: React.ReactNode;
}

export const CollapsibleChart = ({ title, children }: CollapsibleChartProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="mb-8 mt-8 rounded-lg bg-white/10 shadow-lg transition-all duration-300">
      <div
        className="flex cursor-pointer items-center justify-between p-6 hover:bg-white/5"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3 className="text-2xl font-semibold text-primary-foreground">{title}</h3>
        <ChevronDownIcon className={cn('h-6 w-6 transition-transform duration-200', isCollapsed ? 'rotate-180' : '')} />
      </div>
      <div className={cn('overflow-hidden transition-all duration-300', isCollapsed ? 'h-0' : 'h-auto')}>
        <div className="p-6 pt-0">{children}</div>
      </div>
    </div>
  );
};
