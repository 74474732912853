import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Card, ScrollArea, Checkbox, Dialog, DialogContent, DialogHeader, DialogTitle } from '@shadcn/ui';
import { EditIcon, SaveIcon, XIcon, FileText } from 'lucide-react';
import { useToast } from '@shadcn/hooks/use-toast';
import reportServices from 'app/services/report-services';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui';
import { ReportChart } from 'app/components/charts/ReportChart';
import { DataTable } from 'app/components/DataTable';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

interface ReportBuilderProps {
  projectId: string;
  onClose: () => void;
  selectedDateRanges: string[];
  reportName: string;
  dataSource: 'utility-bills' | 'energy-star';
}

interface ChartData {
  type: 'line' | 'bar' | 'pie';
  data: any;
  options?: any;
}

interface ReportSectionData {
  id: string;
  title?: string;
  content: string;
  charts?: ChartData[];
  tables?: any[];
  isEditable: boolean;
  isIncluded: boolean;
}

export interface ReportPageData {
  pageNumber: number;
  title: string;
  sections: ReportSectionData[];
}

const OptionsPanel = ({
  pages,
  onToggleSection,
  onGenerateReport,
  reportName,
  dataSource,
  selectedDateRanges,
}: {
  pages: ReportPageData[];
  onToggleSection: (pageIndex: number, sectionId: string) => void;
  onGenerateReport: () => void;
  reportName: string;
  dataSource: string;
  selectedDateRanges: string[];
}) => {
  const isExportDisabled = !reportName.trim() || !dataSource || selectedDateRanges.length === 0;

  return (
    <Card className="flex h-[calc(100vh-400px)] w-96 flex-col overflow-hidden border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
      <div className="flex items-center justify-between border-b border-border/20 p-4">
        <h2 className="text-xl font-bold text-foreground">Export Options</h2>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <Button onClick={onGenerateReport} disabled={isExportDisabled} className="flex items-center gap-2">
                  <FileText className="h-4 w-4" />
                  Export as PDF
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {isExportDisabled
                ? 'Please ensure you have a report name, data source, and at least one date range selected'
                : 'Export the report with selected options'}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <ScrollArea className="flex-1 p-4">
        <div className="space-y-6">
          {pages.map((page, pageIndex) => (
            <div key={page.pageNumber} className="rounded-lg border border-border/20 bg-background/5 p-4">
              <h3 className="mb-3 font-semibold text-foreground">{page.title}</h3>
              {page.sections.map((section) => (
                <div key={section.id} className="mb-2 flex items-center gap-2 pl-2">
                  <Checkbox
                    checked={section.isIncluded}
                    onCheckedChange={() => onToggleSection(pageIndex, section.id)}
                  />
                  <span className="text-sm text-muted-foreground/80">{section.title}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </ScrollArea>
    </Card>
  );
};

export const ReportPreview = forwardRef<{ handleGenerateReport: () => Promise<void> }, ReportBuilderProps>(
  ({ projectId, onClose, selectedDateRanges, reportName, dataSource }, ref) => {
    const [pages, setPages] = useState<ReportPageData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editingSection, setEditingSection] = useState<{
      pageIndex: number;
      section: ReportSectionData;
      content: string;
    } | null>(null);
    const { toast } = useToast();

    useImperativeHandle(ref, () => ({
      handleGenerateReport: async () => {
        await handleGenerateReport();
      },
    }));

    useEffect(() => {
      fetchReportPreview();
    }, [projectId]);

    const fetchReportPreview = async () => {
      try {
        setIsLoading(true);
        console.log('selectedDateRanges', JSON.stringify(selectedDateRanges, null, 2));
        console.log('dataSource', dataSource);
        const response = await reportServices.getReportPreview(projectId, {
          dataSource,
          dateRanges: selectedDateRanges,
        });
        setPages(response);
      } catch (error: any) {
        console.error('Failed to fetch report preview:', error);
        toast({
          description: error.message,
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    const handleEdit = (pageIndex: number, section: ReportSectionData) => {
      setEditingSection({
        pageIndex,
        section,
        content: section.content,
      });
    };

    const handleSave = (content: string) => {
      if (!editingSection) return;

      setPages(
        pages.map((page, idx) => {
          if (idx === editingSection.pageIndex) {
            return {
              ...page,
              sections: page.sections.map((section) => {
                if (section.id === editingSection.section.id) {
                  return { ...section, content };
                }
                return section;
              }),
            };
          }
          return page;
        })
      );
      setEditingSection(null);
    };

    const handleToggleSection = (pageIndex: number, sectionId: string) => {
      setPages(
        pages.map((page, idx) => {
          if (idx === pageIndex) {
            return {
              ...page,
              sections: page.sections.map((section) => {
                if (section.id === sectionId) {
                  return { ...section, isIncluded: !section.isIncluded };
                }
                return section;
              }),
            };
          }
          return page;
        })
      );
    };

    const handleGenerateReport = async () => {
      try {
        // Filter only included sections
        const filteredPages = pages.map((page) => ({
          ...page,
          sections: page.sections.filter((section) => section.isIncluded),
        }));

        // Only include pages that have at least one section
        const finalPages = filteredPages.filter((page) => page.sections.length > 0);

        // Create the report with selected sections and their edited content
        await reportServices.create({
          project: projectId,
          outputType: 'pdf',
          name: reportName,
          dataSource: dataSource,
          pages: finalPages,
          dateRanges: selectedDateRanges,
        });

        toast({
          description: 'Exporting report...',
          variant: 'positive',
        });

        onClose();
      } catch (error) {
        console.error('Failed to generate report:', error);
        toast({
          description: 'Failed to generate report.',
          variant: 'destructive',
        });
      }
    };

    const renderSection = (pageIndex: number, section: ReportSectionData) => (
      <div key={section.id} className={`mb-6 ${section.title ? 'has-title' : ''}`}>
        {section.title && (
          <div className="mb-2 flex items-center justify-between">
            <h3 className="section-title">{section.title}</h3>
            {section.isEditable && (
              <div className="flex gap-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="sm" onClick={() => handleEdit(pageIndex, section)}>
                        <EditIcon className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Edit content</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
        )}
        <div
          className="report-content prose prose-sm max-w-none text-[#FFFFFF]"
          dangerouslySetInnerHTML={{ __html: section.content }}
        />
        {section.tables?.map((table, index) => (
          <div key={`${section.id}-table-${index}`} className="mt-4">
            <h3>{table.name}</h3>
            <DataTable
              headers={table.headers}
              rows={table.rows}
              isCustomizable={table.isCustomizable}
              onTableChange={(updatedRows) => {
                setPages(
                  pages.map((page, idx) => {
                    if (idx === pageIndex) {
                      return {
                        ...page,
                        sections: page.sections.map((sec) => {
                          if (sec.id === section.id) {
                            return {
                              ...sec,
                              tables: sec.tables?.map((t, i) => (i === index ? { ...t, rows: updatedRows } : t)),
                            };
                          }
                          return sec;
                        }),
                      };
                    }
                    return page;
                  })
                );
              }}
            />
          </div>
        ))}
        {section.charts?.map((chart, index) => (
          <div key={`${section.id}-chart-${index}`} className="mb-8 mt-8">
            <ReportChart type={chart.type} data={chart.data} options={chart.options} />
          </div>
        ))}
      </div>
    );

    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          history: {
            depth: 10,
            newGroupDelay: 500,
          },
        }),
        Heading,
        Table,
        TableRow,
        TableCell,
        TableHeader,
      ],
      content: editingSection?.content,
      onUpdate: ({ editor }) => {
        if (editingSection) {
          setEditingSection({
            ...editingSection,
            content: editor.getHTML(),
          });
        }
      },
      editable: true,
      enableInputRules: true,
      enablePasteRules: true,
      editorProps: {
        attributes: {
          class:
            'prose prose-sm max-w-none focus:outline-none min-h-[500px] text-foreground prose-headings:text-foreground',
        },
      },
    });

    useEffect(() => {
      if (editor && editingSection) {
        editor.commands.setContent(editingSection.content);
      }
    }, [editingSection?.section.id]);

    return (
      <div className="flex h-full gap-8">
        <div className="flex-1">
          {isLoading ? (
            <div className="flex h-full items-center justify-center text-muted-foreground/80">
              Loading report template...
            </div>
          ) : (
            <ScrollArea className="h-[calc(100vh-400px)]">
              {pages.map((page, pageIndex) => (
                <Card
                  key={page.pageNumber}
                  className="mb-4 border-border/20 bg-background/5 p-6 shadow-lg backdrop-blur-sm"
                >
                  <div className="mb-4 flex items-center justify-between border-b border-border/20 pb-2">
                    <div className="text-sm text-muted-foreground/80">Page {page.pageNumber}</div>
                  </div>
                  {page.sections
                    .filter((section) => section.isIncluded)
                    .map((section) => renderSection(pageIndex, section))}
                </Card>
              ))}
            </ScrollArea>
          )}
        </div>
        <OptionsPanel
          pages={pages}
          onToggleSection={handleToggleSection}
          onGenerateReport={handleGenerateReport}
          reportName={reportName}
          dataSource={dataSource}
          selectedDateRanges={selectedDateRanges}
        />

        {editingSection && (
          <Dialog open={true} onOpenChange={() => setEditingSection(null)}>
            <DialogContent className="h-[80vh] w-[800px] border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
              <DialogHeader>
                <DialogTitle className="text-foreground">Edit {editingSection.section.title}</DialogTitle>
              </DialogHeader>
              <EditorContent
                editor={editor}
                className="prose prose-sm mx-auto mb-4 w-full flex-1 overflow-y-auto rounded-md border border-border/20 bg-background/5 p-4 [&_.ProseMirror]:text-foreground"
              />
              <div className="flex justify-end gap-2 border-t border-border/20 pt-4">
                <Button variant="ghost" onClick={() => setEditingSection(null)}>
                  <XIcon className="mr-2 h-4 w-4" />
                  Cancel
                </Button>
                <Button onClick={() => handleSave(editingSection.content)}>
                  <SaveIcon className="mr-2 h-4 w-4" />
                  Save
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
);
