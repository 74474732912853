import { forwardRef, useState } from 'react';
import { cn } from '@shadcn/utils';
import { Building2, Loader2, AlertTriangle } from 'lucide-react';
import { PenaltyData } from 'app/types/penalty-data';
import { DataSource, Documents } from 'app/types/visualizations';
import YearlyEnergySummary from './YearlyEnergySummary';
import EnergyUsageBreakdown from './EnergyUsageBreakdown';
import EmissionsTrendAnalysis from './EmissionsTrendAnalysis';
import Last12Months from './Last12Months';
import type { PropertyMetrics } from 'app/services/energy-star-services';
interface EstimatedPenaltyProps {
  projectId: string;
  dataSource: DataSource;
  emissionsFromUtilityBills: PenaltyData | null;
  emissionsFromMeterReadings: PenaltyData | null;
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
  formattedMeterReadings: Documents;
  formattedDocuments: Documents;
  isLoading: boolean;
}

const UsageSummaryTables = forwardRef<HTMLDivElement, EstimatedPenaltyProps>(
  (
    {
      dataSource,
      emissionsFromUtilityBills,
      emissionsFromMeterReadings,
      energyStarScoreByDateRange,
      formattedMeterReadings,
      formattedDocuments,
      isLoading,
    },
    ref
  ) => {
    const [error, setError] = useState<string | null>(null);

    if (isLoading) {
      return (
        <div className="flex h-[100%] items-center justify-center py-8">
          <Loader2 className={cn('h-20 w-20 animate-spin text-primary')} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex h-[100%] items-center justify-center py-8">
          <div className="rounded-xl bg-white/10 p-8 text-center shadow-lg backdrop-blur-lg backdrop-filter">
            <AlertTriangle className="mx-auto mb-4 h-12 w-12 text-red-500" />
            <div className="text-lg font-semibold">Error: {error}</div>
          </div>
        </div>
      );
    }

    if (
      (dataSource === 'energy-star' && !emissionsFromMeterReadings) ||
      (dataSource === 'utility-bills' && !emissionsFromUtilityBills)
    ) {
      return (
        <div className="flex h-[100%] items-center justify-center p-8">
          <div className="max-w-2xl rounded-xl bg-white/10 p-8 text-center shadow-lg backdrop-blur-lg backdrop-filter">
            <Building2 className="mx-auto mb-4 h-12 w-12 text-primary" />
            <h3 className="mb-2 text-xl font-semibold">No Estimations Available</h3>
            <p className="text-sm text-muted-foreground">
              Upload {dataSource === 'energy-star' ? 'meter readings' : 'utility bills'} for this project to start
              analyzing your energy usage, carbon emissions, and estimated penalties.
            </p>
          </div>
        </div>
      );
    }

    const data = dataSource === 'energy-star' ? emissionsFromMeterReadings : emissionsFromUtilityBills;
    const estimatedAnnual = data?.estimatedAnnual;

    return (
      <div ref={ref} className="space-y-4">
        <Last12Months
          dataSource={dataSource}
          formattedMeterReadings={formattedMeterReadings}
          formattedDocuments={formattedDocuments}
        />
        <YearlyEnergySummary data={estimatedAnnual} energyStarScoreByDateRange={energyStarScoreByDateRange} />
        <EmissionsTrendAnalysis yearsData={estimatedAnnual?.yearsData} />
        <EnergyUsageBreakdown yearsData={estimatedAnnual?.yearsData} />
      </div>
    );
  }
);

export default UsageSummaryTables;
