import { formatNumber, formatTemperature } from 'app/utils/formatters';

export const sharedXAxisProps = {
  dataKey: 'monthYear',
  angle: -45,
  textAnchor: 'end',
  height: 60,
  tick: { fill: 'hsl(var(--foreground))', fontSize: 12 },
  tickMargin: 25,
  label: {
    position: 'bottom',
    offset: 60,
    fill: 'hsl(var(--foreground))',
    fontSize: 14,
  },
};

export const createYAxisProps = (
  label: string,
  options?: {
    orientation?: 'left' | 'right';
    yAxisId?: string;
    tickFormatter?: (value: any) => string;
    dx?: number;
  }
) => {
  const {
    orientation = 'left',
    yAxisId = orientation,
    tickFormatter = formatNumber,
    dx = orientation === 'left' ? -35 : 20,
  } = options || {};

  return {
    yAxisId,
    orientation,
    label: {
      value: label,
      angle: -90,
      position: `inside${orientation === 'left' ? 'Left' : 'Right'}`,
      fill: 'hsl(var(--foreground))',
      fontSize: 14,
      dx,
      dy: orientation === 'left' ? 50 : 30,
    },
    tickFormatter,
    tick: { fill: 'hsl(var(--foreground))', fontSize: 12 },
  };
};

export const sharedCartesianGridProps = {
  strokeDasharray: '3 3',
  stroke: 'rgba(255, 255, 255, 0.1)',
  vertical: false,
};

export const sharedTooltipProps = {
  contentStyle: {
    backgroundColor: 'hsl(var(--background))',
    border: '1px solid hsl(var(--border))',
    borderRadius: '8px',
    padding: '8px 12px',
  },
  labelStyle: { color: 'hsl(var(--foreground))', marginBottom: '4px' },
  itemStyle: { color: 'hsl(var(--foreground))', fontSize: '12px' },
  formatter: formatNumber,
};

export const sharedLegendProps = {
  wrapperStyle: {
    paddingTop: '20px',
  },
};

export const createTemperatureYAxisProps = (label: string = 'Temperature (°F)') => ({
  ...createYAxisProps(label, {
    orientation: 'right',
    yAxisId: 'right',
    tickFormatter: formatTemperature,
  }),
});
