import jwtAuthAxios from './auth/jwtAuth';
import { DataSource } from '../types/visualizations';
import type { PenaltyData } from '../types/penalty-data';
export type WeatherParams = { id?: string; data: { zipCode: string; startDate: string; endDate: string } };

export type EmissionsParams = { id?: string; query: { dataSource: DataSource } };

export interface ProjectAttributes {
  name: string;
  energyStarId: string;
  propertyType: string;
  createdAt: string;
  squareFeet: number;
  yearBuilt: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  childProperties?: Array<{
    name: string;
    energyStarId: string;
  }>;
  services: string[];
}

export interface ProjectInfoData {
  id: string;
  type: string;
  attributes: ProjectAttributes;
  relationships?: {
    [key: string]: {
      data: any;
    };
  };
}

const projectServices = {
  create: async (params) => {
    const { data } = await jwtAuthAxios.post('/v1/projects', params);
    return data;
  },

  get: async (): Promise<ProjectInfoData[]> => {
    const { data } = await jwtAuthAxios.get('/v1/projects');
    return data;
  },

  getById: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/${id}`);
    return data;
  },

  update: async (params) => {
    const { data } = await jwtAuthAxios.put(`/v1/projects/${params.id}`, params.data);
    return data;
  },

  delete: async (id) => {
    const data = await jwtAuthAxios.delete(`/v1/projects/${id}`);
    return data;
  },

  addWeather: async (params: WeatherParams) => {
    if (!params.id) {
      throw new Error('Project ID is required');
    }
    const { data } = await jwtAuthAxios.post(`/v1/projects/weather/${params.id}`, params.data);
    return data;
  },

  fetchProjectInsights: async (params) => {
    const { id } = params;
    const { data } = await jwtAuthAxios.post(`/v1/projects/insights/${id}`, params.data);
    return data;
  },
  getPropertyTypes: async () => {
    const { data } = await jwtAuthAxios.get('/v1/projects/propertyTypes');
    return data;
  },

  getEstimatedPenalties: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/penalties/${id}`);
    return data;
  },

  getEmissions: async (params: EmissionsParams) => {
    const { id, query } = params;
    const { data } = await jwtAuthAxios.get(`/v1/projects/${id}/emissions`, { params: query });
    return data as PenaltyData;
  },

  getMeterReadings: async (id: string) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/insights/${id}/meters`);
    return data;
  },
};

export default projectServices;
