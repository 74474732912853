import { Button } from '@shadcn/ui';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui';
import { Loader2Icon, RefreshCwIcon, XIcon } from 'lucide-react';

interface WeatherPickerProps {
  zipCode: string;
  startDate: string;
  endDate: string;
  isEditing: boolean;
  isWeatherLoading: boolean;
  hasWeatherData: boolean;
  onZipCodeChange: (value: string) => void;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  onSubmit: () => void;
  onStartEditing: () => void;
  onCancelEditing: () => void;
}

export const WeatherPicker = ({
  zipCode,
  startDate,
  endDate,
  isEditing,
  isWeatherLoading,
  hasWeatherData,
  onZipCodeChange,
  onStartDateChange,
  onEndDateChange,
  onSubmit,
  onStartEditing,
  onCancelEditing,
}: WeatherPickerProps) => {
  return (
    <Card className="mx-4 mb-8 animate-fade-up-in rounded-xl border-border">
      <CardHeader>
        <CardTitle>{hasWeatherData ? 'Weather Data' : 'Add Weather Data'}</CardTitle>
        <CardDescription>
          {hasWeatherData
            ? 'Current weather data for this location.'
            : 'Click to submit a request for weather data in this date range.'}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <InputWrapper
          type="text"
          label="ZIP Code"
          placeholder="ZIP Code"
          value={zipCode}
          onChange={(e) => onZipCodeChange(e.target.value)}
          disabled={!isEditing && hasWeatherData}
        />
        <InputWrapper
          type="date"
          label="Start Date"
          value={startDate}
          onChange={(e) => onStartDateChange(e.target.value)}
          disabled={!isEditing && hasWeatherData}
        />
        <InputWrapper
          type="date"
          label="End Date"
          value={endDate}
          onChange={(e) => onEndDateChange(e.target.value)}
          disabled={!isEditing && hasWeatherData}
        />
        {hasWeatherData ? (
          isEditing ? (
            <div className="flex justify-end gap-2">
              <Button onClick={onCancelEditing} variant="outline">
                <XIcon className="mr-2 h-4 w-4" />
                Cancel
              </Button>
              <Button onClick={onSubmit} disabled={isWeatherLoading}>
                {isWeatherLoading ? <Loader2Icon className="mr-2 h-4 w-4 animate-spin" /> : null}
                Submit
              </Button>
            </div>
          ) : (
            <Button onClick={onStartEditing} className="self-end">
              <RefreshCwIcon className="mr-2 h-4 w-4" />
              Edit weather
            </Button>
          )
        ) : (
          <Button onClick={onSubmit} disabled={isWeatherLoading} className="self-end">
            {isWeatherLoading ? <Loader2Icon className="mr-2 h-4 w-4 animate-spin" /> : null}
            Submit
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
