import jwtAuthAxios from './auth/jwtAuth';

const conedService = {
  exchangeCodeForAccessToken: async ({ body }) => {
    const { data } = await jwtAuthAxios.post(`/v1/coned/access-token`, body);
    return data;
  },
};

export default conedService;
