import { Dispatch, SetStateAction } from 'react';
import { Button, Dialog, DialogTrigger } from '@shadcn/ui';
import { PlusIcon } from 'lucide-react';
import { NewReportModal } from './NewReportModal';
import type { ReportOutputType } from '../../../../types/reports';
import type { YearData } from 'app/types/penalty-data';
interface NewReportButtonProps {
  projectId: string;
  outputType: ReportOutputType;
  isDeleting: boolean;
  setRefreshTrigger: Dispatch<SetStateAction<number>>;
  selectedReportType: ReportOutputType | null;
  setSelectedReportType: (type: ReportOutputType | null) => void;
  openNewReportDialog: boolean;
  setOpenNewReportDialog: (open: boolean) => void;
  variant?: 'default' | 'secondary' | 'outline';
  yearsData: YearData[];
}

export function NewReportButton({
  projectId,
  outputType,
  isDeleting,
  setRefreshTrigger,
  selectedReportType,
  setSelectedReportType,
  openNewReportDialog,
  setOpenNewReportDialog,
  variant = 'default',
  yearsData = [],
}: NewReportButtonProps) {
  const buttonText = outputType === 'pdf' ? 'New Emissions Report' : 'New Excel Report';

  return (
    <Dialog
      open={openNewReportDialog && selectedReportType === outputType}
      onOpenChange={(v) => {
        if (v) {
          setSelectedReportType(outputType);
          setOpenNewReportDialog(true);
        } else {
          setSelectedReportType(null);
          setOpenNewReportDialog(false);
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant={variant} disabled={isDeleting}>
          <PlusIcon className="mr-2 h-4 w-4" /> {buttonText}
        </Button>
      </DialogTrigger>
      {openNewReportDialog && selectedReportType === outputType && (
        <NewReportModal
          projectId={projectId}
          outputType={outputType}
          setRefreshTrigger={setRefreshTrigger}
          yearsData={yearsData}
          handleClose={() => {
            setSelectedReportType(null);
            setOpenNewReportDialog(false);
          }}
        />
      )}
    </Dialog>
  );
}
