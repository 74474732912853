import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesForAuthenticatedOnly, routesForNotAuthenticatedOnly } from 'app/routes';
import useRoutePathMatch from '@jumbo/hooks/useRoutePathMatch';
import { config } from 'app/config/main';
import { AuthContext } from '@jumbo/components/JumboAuthProvider/JumboAuthContext';
import { removeToken, storeToken, getToken } from './authHelpers';

const storedToken = getToken();
let firstTimePageLoad = true;

const init = () => {
  let authUser = null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(storedToken); // also sets axios header
  }

  return {
    authToken: storedToken ?? null,
    authUser: authUser,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set-auth-data':
      return {
        ...state,
        ...action.payload,
      };

    case 'start-loading':
      return {
        ...state,
        isLoading: true,
      };

    case 'stop-loading':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export const JumboAuthProvider = ({ children, ...restProps }: PropsWithChildren<Record<string, unknown>>) => {
  const [authOptions, setAuthOptions] = React.useReducer(authReducer, restProps, init);
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const isAuthenticatedRouteOnly = useRoutePathMatch(routesForAuthenticatedOnly);
  const isNotAuthenticatedRouteOnly = useRoutePathMatch(routesForNotAuthenticatedOnly);

  React.useEffect(() => {
    if (logout) {
      removeToken();
      setAuthOptions({
        type: 'set-auth-data',
        payload: { authToken: null, authUser: null, isLoading: false },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(async (token) => {
    setAuthOptions({ type: 'start-loading' });
    if (!token) {
      setLogout(true);
      return;
    }

    storeToken(token);
    try {
      const authUser = await config?.authSetting?.getAuthUserService();
      if (authUser.hasError) {
        setLogout(true);
        return;
      }

      if (authUser) {
        setAuthOptions({
          type: 'set-auth-data',
          payload: { authToken: token, authUser: authUser, isLoading: false },
        });
        return;
      }

      setLogout(true);
    } catch (error) {
      setLogout(true);
      console.error(error);
    }
  }, []);

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: 'set-redirect-path', payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: 'set-auth-data', payload: data });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
    };
  }, [authOptions]);

  React.useEffect(() => {
    if (!authOptions.authToken) {
      if (isAuthenticatedRouteOnly) {
        navigate('/user/login');
      }
    } else if (!authOptions.authUser) {
      setAuthToken(authOptions.authToken);
    } else if (isNotAuthenticatedRouteOnly) {
      if (!firstTimePageLoad) {
        navigate(config.authSetting.redirectNotAuthenticatedPath ?? '/');
      } else {
        firstTimePageLoad = false;
      }
    }
  }, [
    authOptions.authUser,
    authOptions.authToken,
    isAuthenticatedRouteOnly,
    isNotAuthenticatedRouteOnly,
    navigate,
    setAuthToken,
  ]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
