import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatNumber } from 'app/utils/formatters';
import { theme } from 'app/utils/theme';
import { YearData } from 'app/types/penalty-data';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { LineChartIcon } from 'lucide-react';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
} from 'app/components/charts/shared/chartProps';

interface EmissionsTrendAnalysisProps {
  yearsData?: YearData[];
}

const EmissionsTrendAnalysis = ({ yearsData }: EmissionsTrendAnalysisProps) => {
  if (!yearsData?.length) {
    return (
      <NoDataFound
        message="No emissions trend data available"
        submessage="There is no emissions data to display for this time period"
        icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <CollapsibleChart title="Emissions Trend">
      <div className="h-[500px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={yearsData} margin={{ top: 20, right: 80, left: 80, bottom: 100 }}>
            <CartesianGrid {...sharedCartesianGridProps} />
            <XAxis
              {...sharedXAxisProps}
              dataKey="year"
              label={{
                position: 'bottom',
                offset: 10,
              }}
            />
            <YAxis {...createYAxisProps('Emissions (tCO2e)')} tickCount={20} interval={0} />
            <Tooltip {...sharedTooltipProps} />
            <Legend {...sharedLegendProps} />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="actualEmissions"
              name="Actual Emissions"
              stroke="rgb(var(--chart-energy-rgb))"
              strokeWidth={2}
              dot={{ fill: 'rgb(var(--chart-energy-rgb))', r: 4 }}
              activeDot={{ r: 8 }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="threshold"
              name="Emissions Threshold"
              stroke="rgb(var(--chart-temperature-rgb))"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ fill: 'rgb(var(--chart-temperature-rgb))', r: 4 }}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleChart>
  );
};

export default EmissionsTrendAnalysis;
