import { useState, useEffect, forwardRef, useCallback, useMemo } from 'react';
import { ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { Loader2Icon, GaugeIcon, FileTextIcon } from 'lucide-react';
import projectServices from 'app/services/project-services';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import { VisualizationsProps, Documents, WeatherProps } from 'app/types/visualizations';
import { getTrendData, getTrendDataMeterReadings, formatDateForInput } from 'app/utils/trendDataHelpers';

import energyStarServices from 'app/services/energy-star-services';
import { theme } from 'app/utils/theme';
import { PenaltyData } from 'app/types/penalty-data';
import type { FlattenedAlert, PropertyMetrics } from 'app/services/energy-star-services';
import type { Meter, ConsumptionData } from '../../../../types/energy-star';
import { ProjectInfoData } from 'app/services/project-services';
import { WeatherSection } from 'app/components/weather/WeatherSection';
import { ServiceTabs } from 'app/components/service/ServiceTabs';
import { ServiceContent } from 'app/components/service/ServiceContent';

const useEnergyStarData = (energyStarId: string | null, emissionsFromMeterReadings: PenaltyData | null) => {
  const fallbackData = {
    meters: [],
    consumptions: {},
    energyStarScore: { score: null },
    energyStarScoreByDateRange: {},
    noScoreReason: [
      {
        name: 'Project not connected to Energy Star',
        description: 'Sync project with a property in Energy Star to see score.',
      },
    ],
    error: null,
  };

  const [meterData, setMeterData] = useState<{
    meters: Meter[];
    consumptions: { [meterId: string]: ConsumptionData };
    energyStarScoreByDateRange: { [dateRange: string]: PropertyMetrics };
    energyStarScore?: PropertyMetrics;
    noScoreReason?: FlattenedAlert[];
  }>(fallbackData);
  const [error, setError] = useState<string | null>(null);
  const [dateRanges, setDateRanges] = useState<Date[]>([]);

  useEffect(() => {
    if (!energyStarId) {
      setMeterData(fallbackData);
      return;
    }

    const fetchMeterData = async () => {
      try {
        let dateRanges: Date[] = [];
        let energyStarScores: PropertyMetrics[] = [];
        let energyStarScoreByDateRange = {};

        if (emissionsFromMeterReadings?.estimatedAnnual?.yearsData) {
          dateRanges = emissionsFromMeterReadings.estimatedAnnual.yearsData.map((year) => new Date(year.endDate));
          setDateRanges(dateRanges);

          energyStarScores = await Promise.all(
            dateRanges.map((dateRange) =>
              energyStarServices.getPropertyScore(
                energyStarId,
                dateRange.getFullYear().toString(),
                (dateRange.getMonth() + 1).toString()
              )
            )
          );
          energyStarScoreByDateRange = emissionsFromMeterReadings.estimatedAnnual.yearsData.reduce(
            (acc, year, index) => {
              const dateRangeKey = `${new Date(year.startDate).toLocaleDateString()} - ${new Date(year.endDate).toLocaleDateString()}`;
              acc[dateRangeKey] = energyStarScores[index];
              return acc;
            },
            {} as { [dateRange: string]: PropertyMetrics }
          );
        }

        const noScoreReasonResponse = await energyStarServices.getNoScoreReasons(energyStarId);
        const metersResponse = await energyStarServices.getMeters(energyStarId);
        const meters = metersResponse['meters'];
        const consumptions = await Promise.all(
          meters.map(async (meter) => {
            try {
              const consumption = await energyStarServices.getMeterConsumption(meter.id);
              return { meterId: meter.id, consumption };
            } catch (err) {
              console.warn(`Failed to fetch consumption for meter ${meter.id}:`, err);
              return { meterId: meter.id, consumption: { meterConsumption: [] } };
            }
          })
        );

        const consumptionMap = consumptions.reduce(
          (acc, { meterId, consumption }) => {
            acc[meterId] = consumption;
            return acc;
          },
          {} as { [key: string]: ConsumptionData }
        );

        setMeterData({
          meters,
          consumptions: consumptionMap,
          energyStarScoreByDateRange,
          energyStarScore: energyStarScores.length > 0 ? energyStarScores[energyStarScores.length - 1] : undefined,
          noScoreReason: noScoreReasonResponse,
        });
      } catch (err) {
        console.error('Error fetching Energy Star data:', err);
      }
    };

    fetchMeterData();
  }, [energyStarId, emissionsFromMeterReadings]);

  return { ...meterData, error };
};

export const Analysis = forwardRef<HTMLDivElement, VisualizationsProps>(({ projectId, onSnackbar }, ref) => {
  const [documents, setDocuments] = useState<Documents>({});
  const [meterReadings, setMeterReadings] = useState<Documents>({});
  const [emissionsFromUtilityBills, setEmissionsFromUtilityBills] = useState<PenaltyData | null>(null);
  const [emissionsFromMeterReadings, setEmissionsFromMeterReadings] = useState<PenaltyData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [weather, setWeather] = useState({} as WeatherProps);
  const [degreeData, setDegreeData] = useState({});
  const [degreeDataByBillingPeriod, setDegreeDataByBillingPeriod] = useState({});
  const [meterReadingDegreeDataByBillingPeriod, setMeterReadingDegreeDataByBillingPeriod] = useState({});
  const [baseloads, setBaseloads] = useState({});
  const [meterReadingsBaseloads, setMeterReadingsBaseloads] = useState({});
  const [baseTemperature, setBaseTemperature] = useState(65);
  const [isUpdatingDegreeData, setIsUpdatingDegreeData] = useState(false);
  const [project, setProject] = useState<any | null>(null);
  const [isEmissionsLoading, setIsEmissionsLoading] = useState(true);

  const allServices = ['All', ...UTILITY_SERVICES];

  const trendDataByService = useMemo(() => {
    const result = {};

    UTILITY_SERVICES.forEach((serviceType) => {
      const serviceBaseload = baseloads[serviceType] || {};

      result[serviceType] = {
        cdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        hdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
        meterReadingsCdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        meterReadingsHdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
      };
    });
    return result;
  }, [
    documents,
    baseloads,
    weather,
    degreeData,
    degreeDataByBillingPeriod,
    meterReadings,
    meterReadingDegreeDataByBillingPeriod,
  ]);

  const fetchAllProjectData = useCallback(
    async (projectData: ProjectInfoData, baseTemp: number) => {
      setIsLoading(true);
      try {
        const [insightsResponse, meterReadings] = await Promise.all([
          projectServices.fetchProjectInsights({
            id: projectId,
            data: { baseTemp, degreeDataTypes: ['hdd', 'cdd'] },
          }),
          projectServices.getMeterReadings(projectId),
        ]);

        let utilityBillsEmissions: PenaltyData | null = null;
        let meterReadingsEmissions: PenaltyData | null = null;

        try {
          utilityBillsEmissions = await projectServices.getEmissions({
            id: projectId,
            query: { dataSource: 'utility-bills' },
          });
        } catch (error) {
          console.warn('Error fetching utility bills emissions:', error);
        }

        if (projectData.attributes?.energyStarId) {
          try {
            meterReadingsEmissions = await projectServices.getEmissions({
              id: projectId,
              query: { dataSource: 'energy-star' },
            });
          } catch (error) {
            console.warn('Error fetching energy star emissions:', error);
          }
        }

        const { degreeData, degreeDataByBillingPeriod, baseloads, formattedDocuments, weather, zipCode } =
          insightsResponse;

        setBaseloads(baseloads);
        setDegreeData(degreeData);
        setDegreeDataByBillingPeriod(degreeDataByBillingPeriod);
        setDocuments(formattedDocuments);
        setWeather(weather);
        setZipCode(zipCode || '');
        setDatesFromData(formattedDocuments);

        setMeterReadings(meterReadings.formattedMeterReadings);
        setMeterReadingsBaseloads(meterReadings.baseloads);
        setMeterReadingDegreeDataByBillingPeriod(meterReadings.degreeDataByBillingPeriod);

        setEmissionsFromUtilityBills(utilityBillsEmissions);
        if (meterReadingsEmissions) {
          setEmissionsFromMeterReadings(meterReadingsEmissions);
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      } finally {
        setIsUpdatingDegreeData(false);
        setIsLoading(false);
        setIsEmissionsLoading(false);
      }
    },
    [projectId]
  );

  useEffect(() => {
    const initializeProject = async () => {
      try {
        const projectData = await projectServices.getById(projectId);
        setProject(projectData);
        await fetchAllProjectData(projectData, baseTemperature);
      } catch (err) {
        console.error('Error initializing project:', err);
        setIsLoading(false);
      }
    };

    initializeProject();
  }, [projectId, baseTemperature, fetchAllProjectData]);

  const handleSliderChange = (value: number[]) => {
    const newValue = value[0];
    setBaseTemperature(newValue);
  };

  const setDatesFromData = (data: Documents) => {
    if (data && Object.keys(data).length > 0) {
      const utilityWithDates = Object.values(data).find(
        (utility) => utility?.startDate && utility?.endDate && utility.startDate !== '' && utility.endDate !== ''
      );

      if (utilityWithDates) {
        setStartDate(formatDateForInput(utilityWithDates.startDate));
        setEndDate(formatDateForInput(utilityWithDates.endDate));
        return { startDate: utilityWithDates.startDate, endDate: utilityWithDates.endDate };
      }
    }
    return null;
  };

  const {
    energyStarScoreByDateRange,
    noScoreReason,
    error: energyStarError,
  } = useEnergyStarData(project?.attributes?.energyStarId, emissionsFromMeterReadings);

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2Icon className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 px-4 pb-2">
        <Tabs defaultValue="utility-bills" className="flex h-full w-full flex-col">
          <TabsList className="mb-6 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)]">
            <TabsTrigger
              value="utility-bills"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-muted-foreground transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
            >
              <FileTextIcon className="h-4 w-4" />
              Utility Bills
            </TabsTrigger>
            <TabsTrigger
              value="meters"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-muted-foreground transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
            >
              <GaugeIcon className="h-4 w-4" />
              Meters
            </TabsTrigger>
          </TabsList>

          <WeatherSection
            projectId={projectId}
            documents={documents}
            meterReadings={meterReadings}
            weather={weather}
            isWeatherOpen={isWeatherOpen}
            isWeatherLoading={isWeatherLoading}
            isEditing={isEditing}
            zipCode={zipCode}
            startDate={startDate}
            endDate={endDate}
            onWeatherChange={setWeather}
            onWeatherOpenChange={setIsWeatherOpen}
            onEditingChange={setIsEditing}
            onZipCodeChange={setZipCode}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onSnackbar={onSnackbar}
          />

          <ScrollArea className="flex-1 p-4">
            <TabsContent value="utility-bills" className="mt-0">
              <Tabs defaultValue="all" className="w-full">
                <ServiceTabs allServices={allServices} />
                {allServices.map((serviceType) => (
                  <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
                    <ServiceContent
                      serviceType={serviceType}
                      dataSource="utility-bills"
                      documents={documents}
                      meterReadings={meterReadings}
                      trendDataByService={trendDataByService}
                      baseloads={baseloads}
                      meterReadingsBaseloads={meterReadingsBaseloads}
                      baseTemperature={baseTemperature}
                      isUpdatingDegreeData={isUpdatingDegreeData}
                      weather={weather}
                      onBaseTemperatureChange={handleSliderChange}
                      project={project}
                      emissionsFromUtilityBills={emissionsFromUtilityBills}
                      emissionsFromMeterReadings={emissionsFromMeterReadings}
                      isEmissionsLoading={isEmissionsLoading}
                      projectId={projectId}
                      energyStarScoreByDateRange={energyStarScoreByDateRange}
                      noScoreReason={noScoreReason}
                      theme={theme}
                    />
                  </TabsContent>
                ))}
              </Tabs>
            </TabsContent>

            <TabsContent value="meters" className="mt-0">
              <Tabs defaultValue="all" className="w-full">
                <ServiceTabs allServices={allServices} />
                {allServices.map((serviceType) => (
                  <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
                    <ServiceContent
                      serviceType={serviceType}
                      dataSource="energy-star"
                      documents={documents}
                      meterReadings={meterReadings}
                      trendDataByService={trendDataByService}
                      baseloads={baseloads}
                      meterReadingsBaseloads={meterReadingsBaseloads}
                      baseTemperature={baseTemperature}
                      isUpdatingDegreeData={isUpdatingDegreeData}
                      weather={weather}
                      onBaseTemperatureChange={handleSliderChange}
                      project={project}
                      emissionsFromUtilityBills={emissionsFromUtilityBills}
                      emissionsFromMeterReadings={emissionsFromMeterReadings}
                      isEmissionsLoading={isEmissionsLoading}
                      projectId={projectId}
                      energyStarScoreByDateRange={energyStarScoreByDateRange}
                      noScoreReason={noScoreReason}
                      theme={theme}
                    />
                  </TabsContent>
                ))}
              </Tabs>
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </div>
    </div>
  );
});
