import { forwardRef, useState } from 'react';
import { ScrollArea } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { Loader2 } from 'lucide-react';
import { DataSource, Documents } from 'app/types/visualizations';

interface Last12MonthsProps {
  formattedMeterReadings: Documents;
  formattedDocuments: Documents;
  dataSource: DataSource;
}

interface EnergyRowData {
  utilityService: string;
  dateRange?: string;
  totalUsage: string;
  totalKbtu: string;
  siteEUI: string;
  sourceEUI: string;
}

const Last12Months = forwardRef<HTMLDivElement, Last12MonthsProps>(
  ({ dataSource, formattedMeterReadings, formattedDocuments }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const buildEnergyData = () => {
      const rows: EnergyRowData[] = [];
      let totalKbtu = 0;
      let totalSiteEUI = 0;
      let totalSourceEUI = 0;

      const sourceData = dataSource === 'utility-bills' ? formattedDocuments : formattedMeterReadings;

      const services = ['electric', 'gas', 'steam', 'oil', 'water'] as const;
      services.forEach((service) => {
        const serviceData = sourceData[service];
        if (serviceData?.stats?.last12Months?.totalUsage) {
          const last12 = serviceData.stats.last12Months;
          rows.push({
            utilityService: service.charAt(0).toUpperCase() + service.slice(1),
            dateRange:
              last12.billing_start_date && last12.billing_end_date
                ? `${last12.billing_start_date} - ${last12.billing_end_date}`
                : undefined,
            totalUsage: `${last12.totalUsage.toLocaleString()} ${last12.units}`,
            totalKbtu: last12.totalUsageKbtu.toLocaleString(),
            siteEUI: last12.siteEUI.toFixed(2),
            sourceEUI: last12.sourceEUI.toFixed(2),
          });
          totalKbtu += last12.totalUsageKbtu;
          totalSiteEUI += last12.siteEUI;
          totalSourceEUI += last12.sourceEUI;
        }
      });

      // Add total row if we have any data
      if (rows.length > 0) {
        rows.push({
          utilityService: 'Total',
          totalUsage: '-',
          totalKbtu: totalKbtu.toLocaleString(),
          siteEUI: totalSiteEUI.toFixed(2),
          sourceEUI: totalSourceEUI.toFixed(2),
        });
      }

      return rows;
    };

    if (isLoading) {
      return (
        <div className="flex h-[100%] items-center justify-center py-8">
          <Loader2 className={cn('h-20 w-20 animate-spin text-primary')} />
        </div>
      );
    }

    const energyData = buildEnergyData();

    return (
      <CollapsibleChart title="Most Recent 12 Months">
        <div className="overflow-x-auto rounded-lg border border-border/10">
          <table className="w-full min-w-[800px] border-separate border-spacing-0">
            <thead className="sticky top-0 bg-muted/80 backdrop-blur supports-[backdrop-filter]:bg-muted/60">
              <tr>
                <th className="border-b border-border/20 p-4 text-left text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Utility Service
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Total Usage
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Total kBtu
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Site EUI (kBtu/ft²)
                </th>
                <th className="border-b border-border/20 p-4 text-right text-xs font-medium uppercase tracking-wider text-foreground/80">
                  Source EUI (kBtu/ft²)
                </th>
              </tr>
            </thead>
            <tbody className="bg-background/40">
              {energyData.map((row: EnergyRowData) => (
                <tr
                  key={row.utilityService}
                  className={cn(
                    'transition-colors hover:bg-muted/30',
                    row.utilityService === 'Total' && 'bg-muted/40 font-semibold'
                  )}
                >
                  <td className="border-b border-border/10 p-4 text-sm">
                    <div className="font-medium text-foreground/90">{row.utilityService}</div>
                    {row.dateRange && <div className="mt-0.5 text-xs text-muted-foreground/80">{row.dateRange}</div>}
                  </td>
                  <td className="border-b border-border/10 p-4 text-right">
                    <div className="text-sm text-foreground/90">{row.totalUsage}</div>
                  </td>
                  <td className="border-b border-border/10 p-4 text-right">
                    <div className="text-sm text-foreground/90">{row.totalKbtu}</div>
                  </td>
                  <td className="border-b border-border/10 p-4 text-right">
                    <div className="text-sm text-foreground/90">{row.siteEUI}</div>
                  </td>
                  <td className="border-b border-border/10 p-4 text-right">
                    <div className="text-sm text-foreground/90">{row.sourceEUI}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CollapsibleChart>
    );
  }
);

Last12Months.displayName = 'Last12Months';

export default Last12Months;
